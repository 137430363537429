import { ImageStyle, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Gradient } from "./Gradient/Gradient";
import { ShimmerEffect } from "./AttentionGrabbers";
import { GradientProps } from "./Gradient/GradientInterfaces";

export type PhotoPlaceholderType =
  | "default"
  | "topLeftToBottomRight"
  | "topRightToBottomLeft"
  | "bottomRightToTopLeft"
  | "bottomLeftToTopRight";

export const PhotoPlaceholder = (props: {
  type: PhotoPlaceholderType;
  style: StyleProp<ViewStyle | ImageStyle>;
  color?: string;
  loading?: boolean;
}) => {
  const gradientType: GradientProps["type"] = props.type === "default" ? "photoPlaceholder" : props.type;

  return (
    <>
      <View style={[props.style, { overflow: "hidden" }, props.loading ? StyleSheet.absoluteFill : {}]}>
        {!props.loading && <Gradient type={gradientType} />}
        {!!props.loading && <ShimmerEffect backgroundColor="colorGreyDark" />}
      </View>
    </>
  );
};
