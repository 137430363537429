import React, { useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { SectionList, SectionListData, SectionListProps, SectionListRenderItem, View } from "react-native";
import Reanimated, { useAnimatedScrollHandler } from "react-native-reanimated";
import { RecipeLibraryImperativeHandle } from "./RecipeLibraryInterfaces";
import { useScrollToTop } from "@react-navigation/native";
import { AppUserRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { RecipeCardCarousel } from "./RecipeCardCarousel";
import { RecipePressedHandler } from "./RecipeCards";
import { useDispatch } from "../../lib/redux/Redux";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { bottomNop, bottomThrow, filterOutFalsy } from "@eatbetter/common-shared";
import { getPullToRefresh } from "../PullToRefresh";
import { useScreenElementDimensions } from "../ScreenView";
import { Spacer } from "../Spacer";
import { useFilteredCookingSessionAndGroceryRecipes } from "../../lib/composite/RecipeListSelectors";
import { SectionHeading } from "../SectionHeading";
import { PhotoRef } from "@eatbetter/photos-shared";
import { RecipeCollectionCard } from "./RecipeCollectionCard";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { Pressable } from "../Pressable";
import { IconMoreVertical } from "../Icons";

const strings = {
  cookingInProgress: "Cooking in Progress",
  recentlyShopped: "Recently Shopped",
};

// COLLECTIONS-TODO
interface RecipeCollectionInfo {
  type: "system" | "user" | "household";
  title: string;
  images: PhotoRef[];
  recipeCount: number;
}

type ScrollEventHandler = ReturnType<typeof useAnimatedScrollHandler>;

type SectionType = "recipeCarousel" | "collectionList";
interface SectionInfo {
  header?: { type: SectionType; title: string };
}

interface SectionItemBase<TType extends SectionType, TData> {
  type: TType;
  data: TData;
}
type CookingSessionOrGroceryRecipe = SectionItemBase<"recipeCarousel", AppUserRecipe[]>;
type CollectionItem = SectionItemBase<"collectionList", RecipeCollectionInfo>;
type SectionListItem = CookingSessionOrGroceryRecipe | CollectionItem;

type LibraryCollectionsViewData = SectionListData<SectionListItem, SectionInfo>;

type RenderSectionPart = (info: { section: LibraryCollectionsViewData }) => React.ReactElement;
type RenderSectionPartComponent = (props: {
  highlighted: boolean;
  section: SectionInfo;
  leadingSection: SectionInfo;
  trailingSection: SectionInfo;
  leadingItem: SectionListItem;
  trailingItem: SectionListItem;
}) => React.ReactElement;
type RenderSectionItem = SectionListRenderItem<SectionListItem, SectionInfo>;
type KeyExtractor = (item: SectionListItem | LibraryCollectionsViewData, index: number) => string;

const AnimatedSectionList =
  Reanimated.createAnimatedComponent<SectionListProps<SectionListItem, SectionInfo>>(SectionList);

interface Props {
  onPullToRefresh: () => Promise<void>;
  onScroll: ScrollEventHandler;
  editMode: boolean;
}

export const RecipeLibraryCollectionsView = React.forwardRef<RecipeLibraryImperativeHandle, Props>((props, ref) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { bottomTabBarHeight, headerHeight } = useScreenElementDimensions();

  const { cookingSessionRecipes, groceryRecipes } = useFilteredCookingSessionAndGroceryRecipes(undefined);

  // COLLECTIONS-TODO
  const collectionsData: Array<{ sectionTitle: string; items: RecipeCollectionInfo[] }> = [
    {
      sectionTitle: "Course",
      items: [
        {
          type: "system",
          title: "Main",
          images: [] satisfies PhotoRef[],
          recipeCount: 23,
        },
        {
          type: "system",
          title: "Appetizer",
          images: [] satisfies PhotoRef[],
          recipeCount: 14,
        },
        {
          type: "system",
          title: "Side Dish",
          images: [] satisfies PhotoRef[],
          recipeCount: 4,
        },
      ],
    },
  ];

  const refreshControl = useMemo(() => {
    return getPullToRefresh(props.onPullToRefresh, headerHeight);
  }, [props.onPullToRefresh, headerHeight]);

  const contentContainerStyle = useMemo(() => {
    return { paddingBottom: bottomTabBarHeight, paddingTop: headerHeight };
  }, [bottomTabBarHeight, headerHeight]);

  const sectionData: LibraryCollectionsViewData[] = useMemo<LibraryCollectionsViewData[]>(() => {
    const haveCookingSessionOrGroceryRecipes = cookingSessionRecipes.length > 0 || groceryRecipes.length > 0;
    const haveCookingSessionAndGroceryRecipes = cookingSessionRecipes.length > 0 && groceryRecipes.length > 0;

    const cookingSessionOrGroceryRecipeSectionTitle = haveCookingSessionOrGroceryRecipes
      ? haveCookingSessionAndGroceryRecipes
        ? `${strings.cookingInProgress} + ${strings.recentlyShopped}`
        : cookingSessionRecipes.length > 0
        ? strings.cookingInProgress
        : strings.recentlyShopped
      : "";

    return filterOutFalsy<LibraryCollectionsViewData>([
      haveCookingSessionOrGroceryRecipes && !props.editMode
        ? {
            key: "cookingSessionAndGroceryRecipes",
            header: {
              type: "recipeCarousel",
              title: cookingSessionOrGroceryRecipeSectionTitle,
            },
            data: [{ type: "recipeCarousel", data: [...cookingSessionRecipes, ...groceryRecipes] }],
          }
        : undefined,
      ...collectionsData.map<LibraryCollectionsViewData>(section => {
        return {
          key: `collection_key_${section.sectionTitle}`,
          header: {
            type: "collectionList",
            title: section.sectionTitle,
          },
          data: section.items.map(i => {
            return {
              type: "collectionList",
              data: i,
            };
          }),
        };
      }),
    ]);
  }, [cookingSessionRecipes, groceryRecipes, collectionsData, props.editMode]);

  const onPressRecipe: RecipePressedHandler = useCallback(
    recipeId => {
      if (!dispatch(navToCookingSessionIfExists({ type: "search", nav: screen.nav.switchTab, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.recipeDetail, {
          recipeId: recipeId as UserRecipeId,
        });
      }
    },
    [dispatch, screen.nav.goTo, screen.nav.switchTab]
  );

  const renderItem: RenderSectionItem = useCallback(
    ({ item, index, section }) => {
      switch (item.type) {
        case "recipeCarousel": {
          return (
            <RecipeCardCarousel
              items={item.data.map((i, idx) => ({
                ...i,
                index: idx,
                onPress: onPressRecipe,
              }))}
            />
          );
        }
        case "collectionList": {
          return (
            <RecipeCollectionCard
              title={item.data.title}
              images={item.data.images}
              recipeCount={item.data.recipeCount}
              collectionType={item.data.type}
              index={index}
              isEditMode={props.editMode}
              isFirst={index === 0}
              isLast={index === section.data.length - 1}
            />
          );
        }
        default:
          bottomThrow(item);
      }
    },
    [onPressRecipe, props.editMode]
  );

  const itemSeparator: RenderSectionPartComponent = useCallback(() => {
    return (
      <>
        <Spacer vertical={globalStyleConstants.minPadding} unit="pixels" />
      </>
    );
  }, []);

  const renderSectionHeader: RenderSectionPart = useCallback(
    info => {
      const isFirst = sectionData[0]?.key === info.section.key;
      const isLast = sectionData.at(-1)?.key === info.section.key;
      const header = info.section.header;

      if (!header) {
        return <></>;
      }

      return <SectionHeader title={header.title} isFirst={isFirst} isLast={isLast} editMode={props.editMode} />;
    },
    [sectionData, props.editMode]
  );

  const renderSectionFooter: RenderSectionPart = useCallback((_info: { section: SectionInfo }) => {
    return <Spacer vertical={1} />;
  }, []);

  const listHeader: RenderSectionPartComponent = useCallback(() => {
    return (
      <>
        <Spacer vertical={1} />
      </>
    );
  }, []);

  const listFooter: RenderSectionPartComponent = useCallback(() => {
    return (
      <>
        <Spacer vertical={2} />
      </>
    );
  }, []);

  const sectionSeparator: RenderSectionPartComponent = useCallback(() => {
    return <Spacer vertical={1} />;
  }, []);

  const keyExtractor: KeyExtractor = useCallback((item, index) => {
    // There is something strange going on here when we set onViewableItemsChanged that definitely looks like a bug in SectionList.
    // When onViewableItemsChanged is set, on the initial render we get called multiple times here with the entire section data object
    // instead of the item data. If we ignore those, everything seems to work fine. It also doesn't happen on subsequent scrolls.
    if (!("type" in item)) {
      return `section-${item.key}-${index}`;
    }

    switch (item.type) {
      case "recipeCarousel": {
        return item.type;
      }
      case "collectionList": {
        // COLLECTIONS-TODO
        return item.data.title;
      }
      default:
        bottomNop(item);
        return `unknown-item-type-${index}`;
    }
  }, []);

  const listRef = useRef<SectionList<any, any>>(null);
  useScrollToTop(listRef);

  useImperativeHandle(
    ref,
    () => ({
      scrollToTop: (opts?: { animated: boolean }) => {
        if (!listRef.current || sectionData.length === 0) {
          return;
        }
        listRef.current.scrollToLocation({
          sectionIndex: 0,
          itemIndex: 0,
          viewPosition: 1,
          animated: opts?.animated,
        });
      },
    }),
    [listRef, sectionData.length]
  );

  return (
    <AnimatedSectionList
      ref={listRef}
      sections={sectionData}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={itemSeparator}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      SectionSeparatorComponent={sectionSeparator}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      stickySectionHeadersEnabled={false}
      refreshControl={refreshControl}
      contentContainerStyle={contentContainerStyle}
      onScroll={props.onScroll}
      scrollEventThrottle={16}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
    />
  );
});

const SectionHeader = React.memo((props: { title: string; isFirst: boolean; isLast: boolean; editMode: boolean }) => {
  const onPressMoveUp = useCallback(() => {
    // COLLECTIONS-TODO
  }, []);

  const onPressMoveDown = useCallback(() => {
    // COLLECTIONS-TODO
  }, []);

  return (
    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {props.editMode && (
          <>
            <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />
            <ListItemReorderControl
              onPressMoveUp={onPressMoveUp}
              onPressMoveDown={onPressMoveDown}
              isFirst={props.isFirst}
              isLast={props.isLast}
            />
            <Spacer horizontal={1} />
          </>
        )}
        <SectionHeading text={props.title} noPadding={props.editMode} />
      </View>
      {props.editMode && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <SectionHeaderMoreMenu />
          <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />
        </View>
      )}
    </View>
  );
});

export const SectionHeaderMoreMenu = React.memo(() => {
  const onPress = useCallback(() => {
    // COLLECTIONS-TODO
  }, []);

  return (
    <Pressable onPress={onPress}>
      <IconMoreVertical color={globalStyleColors.colorAccentCool} />
    </Pressable>
  );
});
