import { EpochMs, UserId } from "@eatbetter/common-shared";

/**
 * IMPORTANT: IDS CANNOT CHANGE. Otherwise, hidden collections might re-appear
 */
export const filterRecipeCollections: Array<{ id: SystemRecipeCollectionId; name: string }> = [
  { id: "sc:all_recipes", name: "All Recipes" },
  { id: "sc:recently_added", name: "Recently Added" },
];

export interface AppCollectionManifest {
  /**
   * All collections avaialable to the user along with metadata about each.
   */
  collections: AppRecipeCollection[];

  /**
   * Layout information for the collections.
   */
  layout: RecipeCollectionLayoutWithMetadata;
}

export type RecipeCollectionId = UserRecipeCollectionId | SystemRecipeCollectionId;
export type UserRecipeCollectionId = `uc:${string}`;
export type SystemRecipeCollectionId = `sc:${string}`;
export type RecipeCollectionGroupId = UserRecipeCollectionGroupId | SystemRecipeCollectionGroupId;
export type UserRecipeCollectionGroupId = `ug:${string}`;
export type SystemRecipeCollectionGroupId = `sg:${string}`;

export type AppRecipeCollection = AppTagCollection | AppFilterCollection;

/**
 * Tag collections can be applied by the user (or the system in the case of auto-tagging)
 */
export interface AppTagCollection extends AppCollectionBase {
  type: "tag";

  // during a rename operation, the recipe collection will contain both the old and new name
  // and we return them both so that we can map to the correct tag in the UI.
  // If there is no rename, this will be equal to the collection name.
  userTags: string[];

  // system tags are tagged with the ids, and not the string.
  // this will always be empty/undefined for user collections and always non-empty for system tags
  systemTags: string[];

  // If the collection has been deleted recently, we will return it to the client
  deleted?: boolean;
}

/**
 * Filter collections are collections that match a filter. For example, "new recipes" might
 * match a filter of added in the last week. These cannot be applied by the user.
 * Currently, only system filter collections are supported.
 */
export interface AppFilterCollection extends AppCollectionBase {
  type: "filter";
  // during a rename operation, the collection might
  mappedNames?: undefined;

  // these will never be deleted
  deleted?: undefined;
}

interface AppCollectionBase {
  id: RecipeCollectionId;
  name: string;
  source: "user" | "household" | "system";
  hidden?: boolean;
  creatorUserId?: UserId;
  canRename: boolean;
  canDelete: boolean;
  canHide: boolean;
  displayIfEmpty: boolean;
  created: EpochMs;
  version: EpochMs;
}

export interface RecipeCollectionLayoutWithMetadata {
  groups: CollectionGroupDisplay[];
}

export interface CollectionGroupDisplay {
  id: RecipeCollectionGroupId;
  name: string;
  type: "user" | "system";
  canRename: boolean;
  position?: { pinned: "top" | "bottom" };
  collections: Array<CollectionDisplay>;
  /**
   * If true, the UI should not remove this group, even if it's empty
   */
  retainIfEmpty?: boolean;
}

export interface CollectionDisplay {
  id: RecipeCollectionId;
}

/**
 * Operations to modify a collection or layout. Returns an updated AppCollectionManifest
 * This interface reflects that individual collection operations can occur after a user has
 * modified the layout, but not yet commited the changes by tapping done. For example:
 * 1. A user moves a collection position within a group
 * 2. User then taps the context menu for a different collection and hides it
 * At this point, we want to commit the hide change *and* the layout change so that rendering
 * can be driven off of the AppCollectionManifest.
 * Thinking of it another way, taking any individual action on a collection in edit mode is equivalent
 * to tapping "Done".
 */
export interface UpdateRecipeCollectionsArgs {
  /**
   * Operation on a specific collection
   */
  collection?: {
    op:
      | { type: "create"; name: string; group?: RecipeCollectionGroupId }
      | { type: "rename"; id: RecipeCollectionId; from: string; to: string }
      | { type: "delete"; id: RecipeCollectionId }
      | { type: "hide"; id: RecipeCollectionId }
      | { type: "unhide"; id: RecipeCollectionId };
  };

  /**
   * Operation to reorder layout.
   * Adding/deleting/renaming a collection group is accomplished with this interface, along with
   * reordering within a collection group.
   * Note that hiding a collection only affects layout but is accomplished with ModifyRecipeCollectionArgs to reflect
   * UI/backend pattern.
   */
  layout?: RecipeCollectionLayout;
}

export interface RecipeCollectionLayout {
  groups: RecipeCollectionLayoutGroup[];
}

export interface RecipeCollectionLayoutGroup {
  id: RecipeCollectionGroupId;
  name: string;
  collections: RecipeCollectionId[];
}
