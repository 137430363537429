import { UserRecipeId } from "@eatbetter/recipes-shared";
import { useScrollToTop } from "@react-navigation/native";
import React, { useRef, useImperativeHandle, useMemo, useCallback } from "react";
import { SectionList, SectionListData, SectionListProps, SectionListRenderItem, View } from "react-native";
import { RecipeListItem, RecipeListSections } from "../../lib/composite/RecipeListSelectors";
import { useRecipe } from "../../lib/recipes/RecipesSelectors";
import { SearchSessionId } from "../../lib/search/SearchSlice";
import { getPullToRefresh } from "../PullToRefresh";
import { useScreenElementDimensions } from "../ScreenView";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { TBody } from "../Typography";
import { UserRecipePressedHandler, recipeCardConstants, RecipeLibraryCardLoading, RecipeCard } from "./RecipeCards";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { globalStyleColors } from "../GlobalStyles";
import { RecipeLibraryImperativeHandle, ScrollEventHandler } from "./RecipeLibraryInterfaces";
import { useDispatch } from "../../lib/redux/Redux";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import Reanimated from "react-native-reanimated";

const strings = {
  setupShareExtension: {
    headline: "💡 Save any recipe to your library right\nfrom your browser",
    subhead: "",
    buttonText: "Show me",
  },
  noResults: "No results.",
  sections: {
    cooking: "Cooking in Progress",
    shopped: "Recently Shopped",
    other: "All Recipes",
  },
};

const AnimatedSectionList =
  Reanimated.createAnimatedComponent<SectionListProps<RecipeListItem, { title: string }>>(SectionList);

type RecipeLibraryListViewData = SectionListData<RecipeListItem, { title: string }>;

interface Props {
  sections: RecipeListSections;
  onPullToRefresh: () => Promise<void>;
  onScroll: ScrollEventHandler;
  showShareExtensionTip?: boolean;
}

/**
 * Provides the classic list view of the recipe library
 */
export const RecipeLibraryListView = React.forwardRef<RecipeLibraryImperativeHandle, Props>((props, ref) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const onRecipeSelected = useCallback<UserRecipePressedHandler>(
    recipeId => {
      if (!dispatch(navToCookingSessionIfExists({ type: "library", nav: screen.nav.goTo, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.recipeDetail, { recipeId: recipeId as UserRecipeId });
      }
    },
    [dispatch, screen.nav.goTo]
  );

  const { cookingSessionRecipes, groceryListRecipes, otherRecipes } = props.sections;
  const noResults = [cookingSessionRecipes, groceryListRecipes, otherRecipes].every(i => i.length === 0);

  const data: RecipeLibraryListViewData[] = useMemo(
    () => [
      {
        title: strings.sections.cooking,
        data: cookingSessionRecipes,
      },
      {
        title: strings.sections.shopped,
        data: groceryListRecipes,
      },
      {
        title: strings.sections.other,
        data: otherRecipes,
      },
    ],
    [cookingSessionRecipes, groceryListRecipes, otherRecipes]
  );

  const itemHeight = recipeCardConstants.verticalScrollCardHeight;
  const itemMargin = 8;

  const renderItem: SectionListRenderItem<RecipeListItem> = useCallback(
    ({ item, index }) => {
      const leadingVerticalSpace = index !== 0 ? { marginTop: itemMargin } : {};

      return (
        <View style={[{ height: itemHeight, marginHorizontal: itemMargin }, leadingVerticalSpace]}>
          <UserRecipeListItemOrSpinner
            recipeId={item.recipeId}
            index={index}
            onPress={onRecipeSelected}
            searchSessionId={undefined}
          />
        </View>
      );
    },
    [onRecipeSelected, itemHeight, itemMargin]
  );

  // we need to make sure that the key changes if the sort order changes. See the comment on the maintainVisibleContentPosition
  // property of the section list below. We set this in RecipeListSelectors based on the section and sort value.
  const keyExtractor: (item: RecipeListItem, index: number) => string = useCallback(item => {
    return item.listKey;
  }, []);

  const sectionLengths = [cookingSessionRecipes.length, groceryListRecipes.length];
  const singleCategoryMode = sectionLengths.every(i => i === 0);

  const renderSectionHeader = useCallback(
    (info: { section: RecipeLibraryListViewData }) => {
      if (info.section.data.length === 0 || singleCategoryMode) {
        return null;
      }

      return (
        <View key={info.section.title}>
          <SectionHeading text={info.section.title} />
        </View>
      );
    },
    [singleCategoryMode]
  );

  const renderSectionFooter = useCallback(
    (info: { section: RecipeLibraryListViewData }) => {
      if (info.section.data.length === 0 || singleCategoryMode) {
        return null;
      }

      return <Spacer vertical={1} />;
    },
    [singleCategoryMode]
  );

  const sectionSeparator = useCallback(() => {
    if (singleCategoryMode) {
      return null;
    }

    return <Spacer vertical={1} />;
  }, [singleCategoryMode]);

  const listHeader = useCallback(() => {
    return (
      <>
        {noResults && <NoResults />}
        <Spacer vertical={1} />
      </>
    );
  }, [noResults]);

  const listFooter = useCallback(() => {
    return (
      <>
        {props.showShareExtensionTip && (
          <>
            {singleCategoryMode && <Spacer vertical={2} />}
            <View style={{ paddingHorizontal: 20 }}>
              <Spacer vertical={1} />
              <ShareExtensionSetup />
              <Spacer vertical={15} />
            </View>
          </>
        )}
        <Spacer vertical={2} />
      </>
    );
  }, [singleCategoryMode, props.showShareExtensionTip]);

  const { bottomTabBarHeight: paddingBottom, headerHeight: screenHeaderHeight } = useScreenElementDimensions();
  const paddingTop = screenHeaderHeight;

  const contentContainerStyle = useMemo(() => {
    return { paddingBottom, paddingTop };
  }, [paddingBottom, paddingTop]);

  const refreshControl = useMemo(() => {
    return getPullToRefresh(props.onPullToRefresh, paddingTop);
  }, [props.onPullToRefresh, paddingTop]);

  const listRef = useRef<SectionList<any, any>>(null);
  useScrollToTop(listRef);

  useImperativeHandle(
    ref,
    () => ({
      scrollToTop: (opts?: { animated: boolean }) => {
        if (!listRef.current || data.length === 0) {
          return;
        }
        listRef.current.scrollToLocation({
          sectionIndex: 0,
          itemIndex: 0,
          viewPosition: 1,
          animated: opts?.animated,
        });
      },
    }),
    [listRef, data.length]
  );

  const maintainVisibleContentPosition = useRef({ minIndexForVisible: 0 }).current;

  return (
    <AnimatedSectionList
      ref={listRef}
      sections={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      SectionSeparatorComponent={sectionSeparator}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      stickySectionHeadersEnabled={false}
      refreshControl={refreshControl}
      contentContainerStyle={contentContainerStyle}
      onScroll={props.onScroll}
      scrollEventThrottle={16}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      // Without this, the list can get in a very weird state where it endlessly scrolls if in item changes position in the list
      // Specifically:
      // 1. Scroll deep in the list
      // 2. Take an action that would result in an item moving up the list order. This includes editing the recipe (before we changed behavior
      //    to keep list position in that case), adding a recipe to the grocery list, starting cooking, or the addition of a new recipe by another
      //    device in the household, or that the user is logged in to.
      // 3. Once nav'ing back to the list after #2, the list would scroll and scroll
      // This maintains the list position in the case of items being prepended. The docs specifically state that behavior can be wonky if an item is reordered
      // so we makes sure that the key changes in the case of order changing, which should make it appear as a new item in the list. See keyExtractor above.
      maintainVisibleContentPosition={maintainVisibleContentPosition}
    />
  );
});

interface UserRecipeItemOrSpinnerProps {
  recipeId: UserRecipeId;
  index: number;
  onPress: UserRecipePressedHandler;
  searchSessionId: SearchSessionId | undefined;
}

const UserRecipeListItemOrSpinner = React.memo((props: UserRecipeItemOrSpinnerProps) => {
  const recipe = useRecipe(props.recipeId);
  if (!recipe) {
    return <RecipeLibraryCardLoading />;
  }
  return <RecipeCard {...recipe} index={props.index} onPress={props.onPress} searchSessionId={props.searchSessionId} />;
});

const NoResults = React.memo(() => {
  return (
    <View style={{ margin: 20 }}>
      <TBody opacity="medium">{strings.noResults}</TBody>
    </View>
  );
});

const ShareExtensionSetup = React.memo(() => {
  const screen = useScreen();

  const onPressHowTo = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtension);
  }, [screen.nav.modal]);

  return (
    <>
      <TBody align="center">{strings.setupShareExtension.headline}</TBody>
      {/* <TSecondary lineHeight={24} align="center" opacity="opaque">
        {strings.setupShareExtension.subhead}
      </TSecondary> */}
      <Spacer vertical={0.5} />
      <TBody
        align="center"
        onPress={onPressHowTo}
        color={globalStyleColors.colorTextLink}
        fontWeight="medium"
        suppressHighlighting
      >
        {strings.setupShareExtension.buttonText}
      </TBody>
    </>
  );
});
