import { AppDispatch, SyncThunkAction, ThunkAction } from "../redux/Redux";
import {
  AddRecipeArgs,
  AddRecipeFromUrlArgs,
  ArchiveRecipeArgs,
  EditRecipeArgs,
  GetUserRecipeArgs,
  UserRecipeId,
  GetRecipeArgs,
  ShareRecipeArgs,
  SaveSharedRecipeArgs,
  AppRecipeBase,
  RecipeTime,
  AppUserRecipe,
  isStructuredRecipeError,
  RecipeRating,
  AppRecipe,
  NextRecipesStart,
  UserRecipes,
  PartialRecipeId,
  AddRecipeFromPhotosArgs,
  EditUserRecipeAttributionArgs,
  BookCoverInfo,
  UpdateRecipeCollectionsArgs,
  AppCollectionManifest,
} from "@eatbetter/recipes-shared";
import {
  recipeAdded,
  recipesRequested,
  recipesPartialReceived,
  recipesFullReceived,
  recipesErrored,
  recipeEdited,
  singleRecipeReceived,
  saveNewRecipeDraft,
  setSearchFilter,
  RecipeFilters,
  recipesScriptErrored,
  recipesScriptRequested,
  recipesScriptReceived,
  libraryRecipeFetchStarted,
  recipesNoLongerProcessing,
  attributionSet,
  collectionManifestRecieved,
} from "./RecipesSlice";
import { isLoading } from "../redux/ServerData";
import { log } from "../../Log";
import { AppAddPhotoArgs, SetWaitingHandler } from "../Types";
import { EpochMs, filterOutFalsy, newId, StructuredError, switchReturn } from "@eatbetter/common-shared";
import {
  reportPaywallHit,
  reportRecipeAdded,
  reportRecipeDeleted,
  reportRecipeDetailViewed,
  reportRecipeEdited,
  reportRecipeLibraryFiltered,
  reportRecipeLibrarySearched,
  reportRecipeNoteEdited,
  reportRecipeQuotaExceeded,
  reportRecipeRatingEdited,
  reportRecipeShared,
  reportRecipeSourceEdited,
  reportRecipesReceived,
  reportRecipeTagAddedRemoved,
  reportRecipeTimeEdited,
  reportUnsupportedUrlRecipeAdded,
} from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { EditUserRecipeOnSaveArgs } from "../../components/recipes/RecipeEditControl";
import { DeglazeUser } from "@eatbetter/users-shared";
import { EditUserRecipeTagArgs, RecipeTagManifest } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { selectRecipeListSections } from "../composite/RecipeListSelectors";
import { searchRecipes } from "./RecipeSearch";
import { debounce } from "lodash";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";
import { Alert } from "../../components/Alert/Alert";
import { AnalyticsEventPropertyMap, PaywallLocation } from "@eatbetter/composite-shared";
import { ViewRecipeScreenComponentProps } from "../../components/ViewRecipeScreenComponent";
import { selectLibraryRecipe } from "./RecipesSelectors";
import { SearchSessionId, selectSearchSession } from "../search/SearchSlice";
import { addPhotoInBackground } from "../photos/PhotoThunks";

const strings = {
  paywallAlert: {
    title: (publisherName?: string) => `Sign in to ${publisherName ?? "website"}`,
    body: (publisherName?: string) => `Please sign in to ${publisherName ?? "websiste"} to see the full recipe.`,
    confirmButton: "Go back",
  },
};

export const addRecipeFromWebView = (
  args: AddRecipeFromUrlArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<UserRecipeId> => {
  return async (dispatch, _, deps) => {
    log.info("Thunk: addRecipeFromWebView", { args });
    const resp = await deps.api.withReturn(setWaiting).addRecipeFromUrl(args);
    if (resp.data) {
      dispatch(recipeAdded(resp.data));
      const event = reportRecipeAdded({ addedVia: "webViewBrowse", recipe: resp.data });
      dispatch(analyticsEvent(event));
      return resp.data.id;
    } else {
      if (resp.error.code === "recipes/unsupportedUrlError") {
        const event = reportUnsupportedUrlRecipeAdded(args.url);
        dispatch(analyticsEvent(event));
      }

      throw new StructuredError(resp.error);
    }
  };
};

export const addRecipeFromUrl = (
  args: AddRecipeFromUrlArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<UserRecipeId> => {
  return async (dispatch, _, deps) => {
    log.info("Thunk: addRecipeFromUrl", { args });
    const resp = await deps.api.withReturn(setWaiting).addRecipeFromUrl(args);
    if (resp.data) {
      dispatch(recipeAdded(resp.data));
      const event = reportRecipeAdded({ addedVia: "appUrl", recipe: resp.data });
      dispatch(analyticsEvent(event));
      return resp.data.id;
    } else {
      if (resp.error.code === "recipes/unsupportedUrlError") {
        const event = reportUnsupportedUrlRecipeAdded(args.url);
        dispatch(analyticsEvent(event));
      }

      if (resp.error.code === "recipes/importQuotaExceeded") {
        dispatch(analyticsEvent(reportRecipeQuotaExceeded("appAddFromUrl")));
      }

      throw new StructuredError(resp.error);
    }
  };
};

export const getBookCoverInfo = (
  recipeId: UserRecipeId,
  photoArgs: AppAddPhotoArgs,
  onProgress?: (n: number) => void,
  setWaiting?: SetWaitingHandler
): ThunkAction<BookCoverInfo> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: getBookCoverPhotoInfo");

    try {
      setWaiting?.(true);
      const photo = await dispatch(addPhotoInBackground(photoArgs, onProgress));
      const resp = await deps.api.withThrow().getBookCoverInfo({ recipeId, photo });
      return resp.data;
    } finally {
      setWaiting?.(false);
    }
  };
};

export const addRecipeFromPhotos = (
  photosToUpload: AppAddPhotoArgs[],
  onProgress?: (uri: string, percentageComplete: number) => void,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: addRecipeFromPhotos");

    try {
      setWaiting?.(true);
      const photos = await Promise.all(
        photosToUpload.map(p => {
          return dispatch(
            addPhotoInBackground(p, progress => {
              onProgress?.(p.uri, progress);
            })
          );
        })
      );

      const partial = newId<PartialRecipeId>();
      const args: AddRecipeFromPhotosArgs = {
        id: partial,
        photos,
      };

      const resp = await deps.api.withThrow().addRecipeFromPhotos(args);
      dispatch(recipeAdded(resp.data));

      const event = reportRecipeAdded({ addedVia: "appPhotos", recipe: resp.data });
      dispatch(analyticsEvent(event));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const addManualRecipe = (args: AddRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: addManualRecipe", { id: args.id });
    const resp = await deps.api.withThrow(setWaiting).addManualRecipe(args);
    dispatch(recipeAdded(resp.data));
    // clear the draft if one was saved.
    dispatch(saveNewRecipeDraft(undefined));
    const event = reportRecipeAdded({ addedVia: "appManual", recipe: resp.data });
    dispatch(analyticsEvent(event));
  };
};

export const getSavedRecipeDraft = (): SyncThunkAction<EditUserRecipeOnSaveArgs | undefined> => {
  return (_dispatch, getState, _deps) => {
    return getState().recipes.newRecipeDraft;
  };
};

export const getRecipe = (args: GetRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<AppRecipeBase> => {
  return async (_dispatch, _getState, deps) => {
    log.info("Thunk: getRecipe", { args });
    const resp = await deps.api.withThrow(setWaiting).getRecipe(args);
    return resp.data;
  };
};

/**
 * Exclusively for the use of the updateProcessingRecipesReactor
 */
export const updateProcessingLibraryRecipes = (
  toUpdate: UserRecipeId | undefined,
  toRemove: UserRecipeId[]
): ThunkAction<void> => {
  return async (dispatch, _gs, _deps) => {
    log.info("Thunk: updateProcessingLibraryRecipes");
    if (toUpdate) {
      dispatch(libraryRecipeFetchStarted(toUpdate));
      dispatch(getAndStoreUserRecipe({ recipeId: toUpdate })).catch(err => {
        log.errorCaught("Error dispatching getAndStoreUserRecipe from updateProcessingLibraryRecipes", err);
      });
    }

    if (toRemove.length > 0) {
      dispatch(recipesNoLongerProcessing(toRemove));
    }
  };
};

export const getAndStoreUserRecipe = (args: GetUserRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: getAndStoreUserRecipe", { args });
    const resp = await deps.api.withThrow(setWaiting).getUserRecipe(args);
    dispatch(singleRecipeReceived(resp.data));
  };
};

export const editRecipe = (args: EditRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editRecipe", { id: args.id });
    const resp = await deps.api.withThrow(setWaiting).editRecipe(args);
    dispatch(recipeEdited(resp.data));
    const event = reportRecipeEdited({ recipe: resp.data });
    dispatch(analyticsEvent(event));
  };
};

export const editRecipeNote = (
  recipeId: UserRecipeId,
  text: string,
  location: RecipeEditFieldLocation,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editRecipeNote", { id: recipeId });
    const tsClient = deps.time.epochMs();
    const resp = await deps.api.withThrow(setWaiting).editRecipeNote({ recipeId, text, tsClient });
    dispatch(recipeEdited(resp.data));
    debouncedReportRecipeNoteEdited(dispatch, resp.data, location);
  };
};

// use a slightly longer time here - unlike search, we don't care about the intermediate states
// initially thought we could do this on unmount, but that doesn't really work for edits in a cooking session
const debouncedReportRecipeNoteEdited = debounce(
  (dispatch: AppDispatch, recipe: AppUserRecipe, location: RecipeEditFieldLocation) => {
    const event = reportRecipeNoteEdited({ recipe, newNote: recipe.notes?.text ?? "", location });
    dispatch(analyticsEvent(event));
  },
  3000
);

export const editRecipeTime = (
  recipeId: UserRecipeId,
  location: RecipeEditFieldLocation,
  time?: RecipeTime,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editRecipeTime", { id: recipeId });
    const existing = getState().recipes.entities[recipeId];
    if (!existing) {
      log.error(`Attempted to edit time for recipe ${recipeId} but recipe not found.`);
      return;
    }

    const initialTime = existing.time;

    const resp = await deps.api.withThrow(setWaiting).editRecipeTime({ recipeId, time, version: existing.version });
    dispatch(recipeEdited(resp.data));

    const event = reportRecipeTimeEdited({ recipe: resp.data, originalTime: initialTime, newTime: time, location });
    dispatch(analyticsEvent(event));
  };
};

export const editRecipeRating = (
  recipeId: UserRecipeId,
  location: RecipeEditFieldLocation,
  rating: RecipeRating | null,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editRecipeRating", { id: recipeId });
    const existing = getState().recipes.entities[recipeId];
    if (!existing) {
      log.error(`Attempted to edit rating for recipe ${recipeId} but recipe not found.`);
      return;
    }

    const originalRating = existing?.rating;
    const newRating = rating;

    const resp = await deps.api.withThrow(setWaiting).editRecipeRating({ recipeId, rating, version: existing.version });
    dispatch(recipeEdited(resp.data));

    const event = reportRecipeRatingEdited({ recipe: resp.data, originalRating, newRating, location });
    dispatch(analyticsEvent(event));
  };
};

export const editRecipeTag = (
  args: EditUserRecipeTagArgs,
  location: RecipeEditFieldLocation,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editRecipeTag", { args });
    const resp = await deps.api.withThrow(setWaiting).editUserRecipeTag(args);
    dispatch(recipeEdited(resp.data));

    const event = reportRecipeTagAddedRemoved({ update: args, recipe: resp.data, location });
    dispatch(analyticsEvent(event));
  };
};

export const editCollections = (
  args: UpdateRecipeCollectionsArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: editCollections", { args });
    const resp = await deps.api.withThrow(setWaiting).editCollections(args);
    dispatch(collectionManifestRecieved(resp.data));
  };
};

export const editRecipeAttribution = (
  args: EditUserRecipeAttributionArgs,
  screen: AnalyticsEventPropertyMap["Recipe Edit Source From"],
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: editRecipeAttribution", { args });
    const existingRecipe = getState().recipes.entities[args.recipeId];
    const resp = await deps.api.withThrow(setWaiting).editRecipeAttribution(args);
    dispatch(singleRecipeReceived(resp.data));
    dispatch(analyticsEvent(reportRecipeSourceEdited({ existingRecipe, updatedRecipe: resp.data, screen })));
    if (args.type === "book" && args.bookId) {
      dispatch(attributionSet({ type: "book", bookId: args.bookId }));
    } else if (args.type === "userEntered" && args.attribution) {
      dispatch(attributionSet({ type: "userEntered", attribution: args.attribution }));
    }
  };
};

export const archiveRecipe = (args: ArchiveRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: archiveRecipe", { args });
    const recipe = getState().recipes.entities[args.recipeId];

    const { data } = await deps.api.withThrow(setWaiting).archiveRecipe(args);

    if (recipe) {
      dispatch(analyticsEvent(reportRecipeDeleted({ recipe })));
    }

    dispatch(singleRecipeReceived(data));
  };
};

/**
 * Returns local recipe IDs that match an optional query and orders them the same way as the recipe list
 */
export const getLocalRecipes = (search?: string): SyncThunkAction<UserRecipeId[]> => {
  return (_dispatch, getState, _deps) => {
    const state = getState();
    const recipeList = selectRecipeListSections(state);
    const ids = [
      ...recipeList.cookingSessionRecipes.map(r => r.recipeId),
      ...recipeList.groceryListRecipes.map(r => r.recipeId),
      ...recipeList.otherRecipes.map(r => r.recipeId),
    ];

    if (!search) {
      return ids;
    }

    const recipes = filterOutFalsy(ids.map(id => state.recipes.entities[id]));

    const searchScores = searchRecipes(recipes, search);

    return ids
      .filter(id => searchScores.hasOwnProperty(id))
      .sort((a, b) => {
        const aScore = searchScores[a] ?? 0;
        const bScore = searchScores[b] ?? 0;
        return bScore - aScore;
      });
  };
};

export const saveSharedRecipe = (
  args: SaveSharedRecipeArgs,
  sharedVia: "userShared" | "socialPostComment",
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      log.info("Thunk: saveSharedRecipe", { args });
      setWaiting?.(true);
      const resp = await deps.api.withThrow().saveSharedRecipe(args);
      dispatch(recipeAdded(resp.data));
      const event = reportRecipeAdded({ addedVia: sharedVia, recipe: resp.data });
      dispatch(analyticsEvent(event));
    } catch (err) {
      if (isStructuredRecipeError(err) && err.data.code === "recipes/recipeConflict") {
        log.warn("Caught recipes/recipeConflict in saveSharedRecipe thunk", { args });
        return;
      }

      throw err;
    } finally {
      setWaiting?.(false);
    }
  };
};

export const shareRecipe = (
  args: ShareRecipeArgs,
  recipients: DeglazeUser[],
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: shareRecipe", { args });

    try {
      setWaiting?.(true);
      await deps.api.withThrow(setWaiting).shareRecipe(args);
      const recipe = getState().recipes.entities[args.recipeId];
      const recipientUsernames = recipients.map(r => r.username);
      const event = reportRecipeShared({ recipe, recipients: args.recipientIds.length, recipientUsernames });
      dispatch(analyticsEvent(event));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const unarchiveRecipe = (args: ArchiveRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: unarchiveRecipe", { args });
    const { data } = await deps.api.withThrow(setWaiting).unarchiveRecipe(args);
    dispatch(singleRecipeReceived(data));
  };
};

export const loadRecipes = (partialOrFull: "partial" | "full"): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info(`Thunk: loadRecipes ${partialOrFull}`);
    const state = getState();
    const sinceVersion = partialOrFull === "full" ? undefined : state.recipes.meta.version;
    const isFull = sinceVersion === undefined;

    try {
      if (isLoading("recipes.meta", state, s => s.recipes.meta)) {
        log.info("loadRecipes called, but status is already loading");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(recipesRequested(startTime));

      const results: UserRecipes[] = [];
      let collections: AppCollectionManifest | undefined;
      let next: NextRecipesStart | undefined;
      do {
        const { data } = await deps.api.withThrow().getRecipes({ sinceVersion, start: next });
        next = data.next;
        results.push(data);

        // we want the most recent version - in the case of multiple pages
        collections = data.collections;
      } while (next);

      if (results.length > 1) {
        log.logRemote(`Multiple calls required to fetch user recipes: ${results.length}`);
      }

      const actionCreator = isFull ? recipesFullReceived : recipesPartialReceived;

      dispatch(actionCreator({ startTime, data: results }));
      if (collections) {
        dispatch(collectionManifestRecieved(collections));
      }

      if (isFull) {
        const event = reportRecipesReceived({ recipeCount: results.reduce((a, b) => a + b.recipes.length, 0) });
        dispatch(analyticsEvent(event));
      }
    } catch (err) {
      log.errorCaught("Unexpected error fetching recipes", err);
      dispatch(recipesErrored());
    }
  };
};

export const recipeLibraryFiltersChanged = (): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const event = reportRecipeLibraryFiltered({
      filters: state.recipes.filters,
      tagManifest: state.recipes.tagManifest,
    });
    dispatch(analyticsEvent(event));
  };
};

export const searchRecipeLibrary = (args: { query: string }): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    dispatch(setSearchFilter(args.query));

    const state = getState();
    debouncedLibrarySearchAnaltyicsEvent(state.recipes.filters, state.recipes.tagManifest, dispatch);
  };
};

const debouncedLibrarySearchAnaltyicsEvent = debounce(
  (filters: RecipeFilters, tagManifest: RecipeTagManifest, dispatch: AppDispatch) => {
    const event = reportRecipeLibrarySearched({ filters, tagManifest });
    dispatch(analyticsEvent(event));
  },
  2000
);

export const reportRecipeViewedTime = (
  recipeId: UserRecipeId,
  setWaiting?: (v: boolean) => void
): ThunkAction<{ type: "success" | "error" }> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: reportRecipeViewedTime");

    try {
      const resp = await deps.api.withThrow(setWaiting).reportRecipeView({ recipeId });
      dispatch(singleRecipeReceived(resp.data));
      return { type: "success" };
    } catch (err) {
      log.errorCaught(`Error reporting recipe view for ${recipeId}`, err);
      return { type: "error" };
    }
  };
};

export const reportViewScreenRecipeDetailViewed = (
  context: ViewRecipeScreenComponentProps["context"],
  recipe: AppRecipe,
  search?: { sessionId: SearchSessionId; resultIndex?: number }
): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const searchSession =
      context === "search" && search?.sessionId ? selectSearchSession(state.search, search.sessionId) : undefined;
    const filters = searchSession ? { filters: searchSession.filters, context: "search" as const } : undefined;
    const knownEntityId = searchSession?.authorId ?? searchSession?.publisherId;
    const recipeInLibrary = !!selectLibraryRecipe(state, recipe.id);
    const tagManifest = state.recipes.tagManifest;
    const event = reportRecipeDetailViewed({
      recipe,
      location: switchReturn(context, {
        search: "Search Results",
        post: "Social Post",
        share: "User Shared Recipe",
      }),
      filters,
      tagManifest,
      recipeInLibrary,
      knownEntityId,
      searchResultIndex: search?.resultIndex,
    });
    dispatch(analyticsEvent(event));
  };
};

export const reportLibraryRecipeDetailView = (recipe: AppUserRecipe): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: reportLibraryRecipeDetailView");
    const state = getState();
    const event = reportRecipeDetailViewed({
      recipe,
      filters: { filters: state.recipes.filters, context: "library" },
      tagManifest: state.recipes.tagManifest,
      location: "Library",
      recipeInLibrary: true,
    });
    dispatch(analyticsEvent(event));
  };
};

export const reportRecipeCooked = (
  recipeId: UserRecipeId,
  cookingSessionId: string,
  startTime: EpochMs
): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    log.info("Thunk: reportRecipeCooked");
    const api = deps.api.withThrow();
    await api.reportRecipeCooked({ recipeId, cookingSessionId, startTime });

    // update the recipe since the last cooked time has changed.
    // This will ensure it doesn't remain in the "recently shopped" section of the recipe list if the cooking session
    // is completed.
    // User impact is very minimal if this fails (just the potential for it to show up in the wrong section of the recipe
    // list until recipes are fetched, so just fail silently since the main work is already done.
    dispatch(getAndStoreUserRecipe({ recipeId })).catch(err => {
      log.errorCaught("Unexpected error getting recipe in reportRecipeCooked", err);
    });
  };
};

export const loadRecipesScript = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    try {
      log.info("Thunk: loadRecipesScript");
      const state = getState();
      if (isLoading("recipes.script", state, s => s.recipes.script)) {
        log.info("loadRecipesScript called, but status is already loading");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(recipesScriptRequested(startTime));

      const resp = await deps.api.withThrow().getRecipesScript();
      dispatch(recipesScriptReceived({ startTime, data: resp.data }));
    } catch (err) {
      log.errorCaught("Unexpected error in loadRecipesScript", err);
      dispatch(recipesScriptErrored());
    }
  };
};

export const reportRecipePaywallHit = (recipe: AppRecipeBase, context: PaywallLocation): SyncThunkAction<void> => {
  return dispatch => {
    const event = reportPaywallHit({ recipe, context });
    dispatch(analyticsEvent(event));
  };
};

/**
 * Alerts the user that a site's paywall is up and that they need to sign in to see the full recipe
 */
export const alertWebRecipePaywallIsUp = (
  recipe: AppRecipeBase,
  context: Extract<PaywallLocation, "Reader Mode" | "Edit Recipe">,
  onConfirmAlert: () => void
): SyncThunkAction<void> => {
  return dispatch => {
    const publisherName = recipe.publisher?.name;

    Alert.alert(strings.paywallAlert.title(publisherName), strings.paywallAlert.body(publisherName), [
      {
        type: "save",
        text: strings.paywallAlert.confirmButton,
        onPress: onConfirmAlert,
      },
    ]);

    dispatch(reportRecipePaywallHit(recipe, context));
  };
};
