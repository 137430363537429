import { AppUserRecipe, RecipeInfo } from "@eatbetter/recipes-shared";
import { EntityResult, SearchRecipeResult } from "@eatbetter/search-shared";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  LayoutAnimation,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItem,
  StyleSheet,
  View,
} from "react-native";
import { RecipeCard, RecipePressedHandler, recipeCardConstants } from "./recipes/RecipeCards";
import { useScreenElementDimensions } from "./ScreenView";
import { useScreen } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { bottomNop, bottomThrow, filterOutFalsy, formatNumberWithCommas, switchReturn } from "@eatbetter/common-shared";
import { RecipeCardCarousel, recipeCardCarouselHeight } from "./recipes/RecipeCardCarousel";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { useDispatch } from "../lib/redux/Redux";
import { navToCookingSessionIfExists } from "../navigation/NavThunks";
import { Spacer } from "./Spacer";
import { TSecondary } from "./Typography";
import { Spinner } from "./Spinner";
import { SearchSessionId, searchResultsScrolledFromTop, searchResultsScrolledToTop } from "../lib/search/SearchSlice";
import { EntityListItem, EntitySelectedHandler, selectEntityConstants } from "./SelectEntity";
import { navToEntityScreen } from "../lib/social/SocialThunks";
import { useTrackScrollSession } from "../lib/util/UseTrackScrollSession";
import { useSearchResultsScrolled } from "../lib/search/SearchSelectors";
import { searchResultsScrolled, searchResultsViewed } from "../lib/search/SearchThunks";

const strings = {
  libraryResultsHeader: (count: number) => ` ${getRecipeCountString(count)} in your library`,
  serverResultsHeader: (count: number) => ` ${getRecipeCountString(count)}`,
  noServerResults: ["Try adjusting your search or ", "import any web recipe with just two taps."] as const,
  loadingServerResults: "Searching ...",
};

function getRecipeCountString(count: number) {
  return `recipe${count === 1 ? "" : "s"}`;
}

interface SearchResultsProps {
  libraryRecipes?: AppUserRecipe[];
  serverRecipes: SearchRecipeResult[];
  entities?: EntityResult[];
  serverRecipeCount?: number;
  serverRecipeCountMaxExceeded?: boolean;
  serverResultsLoading?: boolean;
  onEndReached: () => void;
  searchSessionId: SearchSessionId;
}

type SectionType = "entityResults" | "libraryResults" | "serverResults";
interface Section {
  header?: { type: SectionType; resultCount?: number; resultCountMaxExceeded?: boolean; loading?: boolean };
}

interface SectionItemBase<TType extends SectionType, TData> {
  type: TType;
  data: TData;
}
type EntitiesSectionListItem = SectionItemBase<"entityResults", EntityResult>;
type LibraryRecipesSectionListItem = SectionItemBase<"libraryResults", AppUserRecipe[]>;
type ServerRecipesSectionListItem = SectionItemBase<"serverResults", RecipeInfo>;
type SectionListItem = EntitiesSectionListItem | LibraryRecipesSectionListItem | ServerRecipesSectionListItem;

type SearchResultsData = SectionListData<SectionListItem, Section>;

type RenderSectionPart = (info: { section: SearchResultsData }) => React.ReactElement;
type RenderSectionPartComponent = (props: {
  highlighted: boolean;
  section: Section;
  leadingSection: Section;
  trailingSection: Section;
  leadingItem: SectionListItem;
  trailingItem: SectionListItem;
}) => React.ReactElement;
type RenderSectionItem = SectionListRenderItem<SectionListItem, Section>;
type KeyExtractor = (item: SectionListItem | SearchResultsData, index: number) => string;

const itemSpacing = globalStyleConstants.minPadding;
const itemHeight = recipeCardConstants.verticalScrollCardHeight;

export const SearchResults = React.memo((props: SearchResultsProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { bottomTabBarHeight } = useScreenElementDimensions();

  const alreadyReportedView = useRef(false);

  useEffect(() => {
    if (!alreadyReportedView.current && !props.serverResultsLoading && screen.nav.focused) {
      dispatch(
        searchResultsViewed({
          sessionId: props.searchSessionId,
          entityResultCount: props.entities?.length,
          libraryResultCount: props.libraryRecipes?.length,
          serverRecipeResultCount: props.serverRecipeCount,
        })
      );
      alreadyReportedView.current = true;
    }
  }, [props.serverResultsLoading, screen.nav.focused]);

  const onPressEntity: EntitySelectedHandler = useCallback(
    item => {
      if ("type" in item.entity) {
        dispatch(navToEntityScreen(item.entity.id, screen.nav, "search"));
      }
    },
    [dispatch]
  );

  const onPressRecipe: RecipePressedHandler = useCallback(
    (recipeId, index) => {
      if (!dispatch(navToCookingSessionIfExists({ type: "search", nav: screen.nav.switchTab, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.searchViewRecipe, {
          recipeId,
          searchSessionId: props.searchSessionId,
          searchResultIndex: index,
        });
      }
    },
    [dispatch, props.searchSessionId, screen.nav.goTo, screen.nav.switchTab]
  );

  const sectionData: SearchResultsData[] = useMemo<SearchResultsData[]>(
    () =>
      filterOutFalsy([
        props.entities && {
          key: "entityResults",
          data: props.entities.map(i => ({ type: "entityResults", data: i })),
        },
        props.libraryRecipes && {
          key: "libraryResults",
          header: { type: "libraryResults", resultCount: props.libraryRecipes.length },
          data: [{ type: "libraryResults", data: props.libraryRecipes }],
        },
        {
          key: "serverResults",
          header: {
            type: "serverResults",
            resultCount: props.serverRecipeCount,
            resultCountMaxExceeded: props.serverRecipeCountMaxExceeded,
            loading: props.serverResultsLoading,
          },
          data: props.serverRecipes.map(i => ({ type: "serverResults", data: i.recipe })),
        },
      ]),
    [
      props.entities,
      props.serverRecipeCount,
      props.serverRecipeCountMaxExceeded,
      props.serverResultsLoading,
      props.libraryRecipes,
      props.serverRecipes,
    ]
  );

  useEffect(() => {
    if (screen.nav.focused) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [sectionData]);

  const renderItem: RenderSectionItem = useCallback(
    ({ item, index }) => {
      switch (item.type) {
        case "entityResults": {
          const height = selectEntityConstants.listItemHeight;
          return (
            <View style={{ height, marginHorizontal: 2 * globalStyleConstants.minPadding }}>
              <EntityListItem
                item={{ type: "followable", context: "searchResults", entity: item.data.entity }}
                onPressItem={onPressEntity}
              />
            </View>
          );
        }
        case "libraryResults": {
          const height = item.data.length > 0 ? recipeCardCarouselHeight : 0;
          return (
            <View style={{ height }}>
              <RecipeCardCarousel
                items={item.data.map((i, idx) => ({
                  ...i,
                  index: idx,
                  onPress: onPressRecipe,
                  searchSessionId: props.searchSessionId,
                }))}
              />
            </View>
          );
        }
        case "serverResults": {
          const height = itemHeight;
          return (
            <View style={{ height, marginHorizontal: itemSpacing }}>
              <RecipeCard
                {...item.data}
                index={index}
                onPress={onPressRecipe}
                searchSessionId={props.searchSessionId}
              />
            </View>
          );
        }
        default:
          bottomThrow(item);
      }
    },
    [onPressEntity, onPressRecipe]
  );

  const keyExtractor: KeyExtractor = useCallback((item, index) => {
    // There is something strange going on here when we set onViewableItemsChanged that definitely looks like a bug in SectionList.
    // When onViewableItemsChanged is set, on the initial render we get called multiple times here with the entire section data object
    // instead of the item data. If we ignore those, everything seems to work fine. It also doesn't happen on subsequent scrolls.
    if (!("type" in item)) {
      return `section-${item.key}-${index}`;
    }

    switch (item.type) {
      case "entityResults": {
        return item.data.entity.id;
      }
      case "libraryResults": {
        return item.type;
      }
      case "serverResults": {
        return item.data.id;
      }
      default:
        bottomNop(item);
        return `unknown-item-type-${index}`;
    }
  }, []);

  const renderSectionHeader: RenderSectionPart = useCallback(
    info => {
      const isFirst = sectionData[0]?.key === info.section.key;
      const header = info.section.header;
      const height = header
        ? getSectionHeaderHeight(header.type, !!header.resultCount, !!props.serverResultsLoading)
        : 0;

      if (!header) {
        return <></>;
      }

      return (
        <View key={info.section.key} style={{ height }}>
          <SectionHeader
            count={header.resultCount}
            maxCountExceeded={header.resultCountMaxExceeded}
            type={header.type}
            isFirst={isFirst}
            loading={header.loading}
          />
        </View>
      );
    },
    [sectionData[0]?.key]
  );

  const renderItemSeparator: RenderSectionPartComponent = useCallback(() => {
    const height = itemSpacing;
    return <Spacer vertical={height} unit="pixels" />;
  }, []);

  const renderSectionSeparator: RenderSectionPartComponent = useCallback(() => {
    const height = globalStyleConstants.unitSize;
    return <Spacer vertical={height} unit="pixels" />;
  }, []);

  const renderSectionFooter: RenderSectionPart = useCallback(info => {
    const height = info.section.data.length > 0 ? globalStyleConstants.unitSize : 0;
    return <Spacer key={info.section.key} vertical={height} unit="pixels" />;
  }, []);

  const renderListFooter: RenderSectionPartComponent = useCallback(() => {
    return <>{!!props.serverResultsLoading && <LoadingFooter />}</>;
  }, [props.serverResultsLoading]);

  const stateIsScrolled = useSearchResultsScrolled(props.searchSessionId);

  const onScroll: SectionListProps<SectionListItem, Section>["onScroll"] = ({ nativeEvent: { contentOffset } }) => {
    const isScrolled = contentOffset.y > 0;
    if (isScrolled !== stateIsScrolled) {
      dispatch(
        isScrolled
          ? searchResultsScrolledFromTop(props.searchSessionId)
          : searchResultsScrolledToTop(props.searchSessionId)
      );
    }
  };

  const reportScrolledEvent = useCallback(
    (indexReached: number) => {
      dispatch(searchResultsScrolled({ sessionId: props.searchSessionId, indexReached }));
    },
    [props.searchSessionId]
  );

  const { onViewableItemsChanged } = useTrackScrollSession({ isScrolled: stateIsScrolled, reportScrolledEvent });

  const contentInset = useMemo(() => ({ bottom: bottomTabBarHeight }), [bottomTabBarHeight]);
  const paddingBottom = useMemo(() => ({ paddingBottom: 3 * globalStyleConstants.unitSize }), []);

  return (
    <SectionList
      sections={sectionData}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={renderItemSeparator}
      renderSectionHeader={renderSectionHeader}
      SectionSeparatorComponent={renderSectionSeparator}
      renderSectionFooter={renderSectionFooter}
      ListFooterComponent={renderListFooter}
      stickySectionHeadersEnabled={false}
      showsVerticalScrollIndicator={false}
      onEndReached={props.onEndReached}
      contentInset={contentInset}
      contentContainerStyle={paddingBottom}
      onEndReachedThreshold={1}
      onScroll={onScroll}
      onViewableItemsChanged={onViewableItemsChanged}
    />
  );
});

const getSectionHeaderHeight = (type: SectionType, haveResults: boolean, loading: boolean) => {
  const sectionHeaderHeight = 24;
  const sectionHeaderNoResultsHeight = 84;

  switch (type) {
    case "entityResults": {
      return 0;
    }
    case "libraryResults": {
      return sectionHeaderHeight;
    }
    case "serverResults": {
      if (!haveResults && !loading) {
        return sectionHeaderNoResultsHeight;
      }
      return sectionHeaderHeight;
    }
    default:
      bottomThrow(type);
  }
};

const SectionHeader = React.memo(
  (props: {
    type: SectionType;
    count: number | undefined;
    maxCountExceeded?: boolean;
    isFirst: boolean;
    loading?: boolean;
  }) => {
    const screen = useScreen();

    const onPressLink = useCallback(() => {
      screen.nav.modal(navTree.get.screens.onboardShareExtension);
    }, [screen.nav.modal]);

    const haveResults = !!props.count;
    const showCount = !props.loading;

    const header = switchReturn(props.type, {
      entityResults: undefined,
      libraryResults: strings.libraryResultsHeader(props.count ?? 0),
      serverResults: () => {
        if (props.loading) {
          return strings.loadingServerResults;
        }
        return strings.serverResultsHeader(props.count ?? 0);
      },
    });

    const subHeader = switchReturn(props.type, {
      entityResults: undefined,
      libraryResults: undefined,
      serverResults: () => {
        if (!props.loading && !haveResults) {
          return { message: strings.noServerResults[0], link: strings.noServerResults[1] };
        }
        return undefined;
      },
    });

    return (
      <View style={[styles.sectionPart, { height: getSectionHeaderHeight(props.type, haveResults, !!props.loading) }]}>
        {!!header && (
          <TSecondary opacity="dark" numberOfLines={1} adjustsFontSizeToFit>
            {showCount && (
              <TSecondary fontWeight="medium">
                {formatNumberWithCommas(props.count ?? 0) + (props.maxCountExceeded ? "+" : "")}
              </TSecondary>
            )}
            <TSecondary>{header}</TSecondary>
          </TSecondary>
        )}
        {!!subHeader && (
          <>
            <Spacer vertical={1} />
            <TSecondary opacity="dark" numberOfLines={2} adjustsFontSizeToFit>
              <TSecondary>{subHeader.message}</TSecondary>
              <TSecondary
                onPress={onPressLink}
                suppressHighlighting
                color={globalStyleColors.colorTextLink}
                fontWeight="medium"
              >
                {subHeader.link}
              </TSecondary>
            </TSecondary>
          </>
        )}
      </View>
    );
  }
);

const LoadingFooter = React.memo(() => {
  return (
    <View style={{ justifyContent: "center", alignItems: "center", paddingVertical: globalStyleConstants.unitSize }}>
      <Spinner />
    </View>
  );
});

const styles = StyleSheet.create({
  sectionPart: {
    justifyContent: "center",
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
});
