import React, { Dispatch, useEffect, useRef } from "react";
import { RecipeIngredient, ShoppableRecipeIngredient } from "@eatbetter/recipes-shared";
import { TextInput, TextInputHandle } from "../TextInput";
import { LayoutAnimation, Platform, View } from "react-native";
import { IconCart, IconChevronLeft, IconEx } from "../Icons";
import { Pressable } from "../Pressable";
import { globalStyleConstants } from "../GlobalStyles";
import { Spacer } from "../Spacer";
import { RecipeIngredientEditActions } from "./RecipeEditControl";
import { ParsedIngredientSummary } from "@eatbetter/items-shared";
import { TSecondary } from "../Typography";
import { ContainerPadded } from "../Containers";
import { RecipeEditTextInput } from "./RecipeEditTextInput";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { DeleteListItemButton } from "../Buttons";
import { switchReturn } from "@eatbetter/common-shared";
import { Haptics } from "../Haptics";
import { useRecipeEditRemoveItemConfirmation } from "./RecipeEditRemoveConfirmation";

const strings = {
  placeholderText: "Ingredient",
};

interface Props {
  // Enable admin features
  admin: boolean;
  sectionIndex: number;
  index: number;
  ingredientCount: number;
  ingredient: RecipeIngredient;
  dispatch: Dispatch<RecipeIngredientEditActions>;
  onAdd: () => void;
  addOnDone: boolean;
  /**
   * Whether to focus an empty input on add. Default is true.
   */
  autofocus?: boolean;
  parsedIngredient?: ParsedIngredientSummary;
}

export const RecipeIngredientEdit = React.memo((props: Props) => {
  const ref = useRef<TextInputHandle>(null);

  // autofocus wasn't working correctly with the keyboard aware scroll view when adding an ingredient
  // via the "+" button, but this does.
  useEffect(() => {
    if (props.ingredient.text === "" && props.autofocus !== false) {
      ref.current?.focus();
    }
  }, []);

  const convertToSection = () => {
    props.dispatch({ type: "convertToSection", sectionIndex: props.sectionIndex, itemIndex: props.index });
  };

  const updateText = (text: string) => {
    props.dispatch({ type: "updateIngredientText", sectionIndex: props.sectionIndex, index: props.index, text });
  };

  const remove = () => {
    layoutAnimation();
    props.dispatch({ type: "removeItem", sectionIndex: props.sectionIndex, itemIndex: props.index });
  };

  const onRemove = useRecipeEditRemoveItemConfirmation({ type: "ingredient", isEmpty: !props.ingredient.text, remove });

  const move = (type: "up" | "down") => {
    const indexDelta = switchReturn(type, {
      up: -1,
      down: 1,
    });

    layoutAnimation();
    props.dispatch({
      type: "moveItem",
      sectionIndex: props.sectionIndex,
      fromIndex: props.index,
      toIndex: props.index + indexDelta,
    });
  };

  const moveUp = () => {
    move("up");
  };

  const moveDown = () => {
    move("down");
  };

  const addShoppable = () => {
    props.dispatch({ type: "addShoppable", sectionIndex: props.sectionIndex, ingredientIndex: props.index });
  };

  const addShoppableSuggestion = () => {
    const shoppableSuggestion = props.parsedIngredient?.shoppablePhrase;
    if (shoppableSuggestion) {
      props.dispatch({
        type: "addShoppable",
        sectionIndex: props.sectionIndex,
        ingredientIndex: props.index,
        text: shoppableSuggestion,
      });
    }
  };

  const onDone = () => {
    // if it's the last item in the list, add a new item on done
    if (props.addOnDone) {
      props.onAdd();
    }
  };

  const hasShoppable = (props.ingredient.shoppable?.length ?? 0) > 0;
  const hasShoppableSuggestion =
    props.parsedIngredient?.shoppablePhrase && props.parsedIngredient.shoppablePhrase !== props.ingredient.text;

  return (
    <View>
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginBottom: globalStyleConstants.unitSize / 4,
          }}
        >
          <DeleteListItemButton onPress={onRemove} size="xsmall" />
          <Spacer horizontal={1} />
          <RecipeEditTextInput
            ref={ref}
            placeholderText={strings.placeholderText}
            value={props.ingredient.text}
            onChangeText={updateText}
            onSubmit={onDone}
          />
          <Spacer horizontal={1} />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {props.admin && (
              <>
                <Pressable onPress={convertToSection}>
                  <IconChevronLeft size={globalStyleConstants.unitSize * 1.5} />
                </Pressable>
                <Spacer horizontal={1} />
                <Pressable onPress={addShoppable}>
                  <IconCart size={globalStyleConstants.unitSize * 1.5} />
                </Pressable>
                <Spacer horizontal={1} />
              </>
            )}
            <ListItemReorderControl
              onPressMoveDown={moveDown}
              onPressMoveUp={moveUp}
              isFirst={props.index === 0}
              isLast={props.index === props.ingredientCount - 1}
            />
          </View>
        </View>
        {props.admin && !hasShoppable && !!hasShoppableSuggestion && !!props.parsedIngredient?.shoppablePhrase && (
          <Pressable onPress={addShoppableSuggestion}>
            <ContainerPadded left={1} top={0.5}>
              <TSecondary>{props.parsedIngredient.shoppablePhrase}</TSecondary>
            </ContainerPadded>
          </Pressable>
        )}
        {props.admin &&
          (props.ingredient.shoppable ?? []).map((ingredient, idx) => {
            // This logic means that if the user presses done/types enter while the last shoppable ingredient on the last
            // ingredient in the section is focused, then a new item will be added
            const addOnDone = props.addOnDone && idx === (props.ingredient.shoppable?.length ?? 0) - 1;
            return (
              <ShoppableRecipeIngredientInput
                key={ingredient.id}
                shoppableIngredient={ingredient}
                sectionIndex={props.sectionIndex}
                ingredientIndex={props.index}
                shoppableIndex={idx}
                dispatch={props.dispatch}
                onAdd={props.onAdd}
                addOnDone={addOnDone}
              />
            );
          })}
      </View>
    </View>
  );
});

interface ShoppableRecipeIngredientInputProps {
  sectionIndex: number;
  ingredientIndex: number;
  shoppableIndex: number;
  shoppableIngredient: ShoppableRecipeIngredient;
  dispatch: Dispatch<RecipeIngredientEditActions>;
  addOnDone: boolean;
  onAdd: () => void;
}

const ShoppableRecipeIngredientInput = React.memo((props: ShoppableRecipeIngredientInputProps) => {
  const ref = useRef<TextInputHandle>(null);

  // autofocus wasn't working correctly with the keyboard aware scroll view when adding an ingredient
  // via the "+" button, but this does.
  useEffect(() => {
    if (props.shoppableIngredient.text === "") {
      ref.current?.focus();
    }
  }, []);

  const textUpdated = (text: string) => {
    props.dispatch({
      type: "updateShoppableText",
      sectionIndex: props.sectionIndex,
      ingredientIndex: props.ingredientIndex,
      shoppableIndex: props.shoppableIndex,
      text,
    });
  };

  const remove = () => {
    props.dispatch({
      type: "removeShoppable",
      sectionIndex: props.sectionIndex,
      ingredientIndex: props.ingredientIndex,
      shoppableIndex: props.shoppableIndex,
    });
  };

  const onDone = () => {
    if (props.addOnDone) {
      props.onAdd();
    }
  };

  return (
    <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
      <IconCart size={globalStyleConstants.unitSize * 1.5} />
      <Spacer horizontal={0.5} />
      <View style={{ flex: 1 }}>
        <TextInput
          value={props.shoppableIngredient.text}
          onChangeText={textUpdated}
          multiline
          scrollEnabled={false}
          autoFocus={props.shoppableIngredient.text === ""}
          returnKeyType="done"
          onSubmit={onDone}
        />
      </View>
      <Spacer horizontal={0.5} />
      <View style={{ width: globalStyleConstants.unitSize * 3 }}>
        <Pressable onPress={remove}>
          <IconEx size={globalStyleConstants.unitSize * 1.5} />
        </Pressable>
      </View>
    </View>
  );
});

function layoutAnimation() {
  if (Platform.OS === "web") {
    return;
  }
  Haptics.feedback("itemStatusChanged");
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}
