import React from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors } from "./GlobalStyles";

export const RadioButton = React.memo((props: { isSelected: boolean }) => {
  return (
    <View style={styles.outline}>
      {props.isSelected && (
        <>
          <View style={styles.filledBackground}></View>
          <View style={styles.filled} />
        </>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  outline: {
    borderRadius: Number.MAX_SAFE_INTEGER,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("colorAccentCool", "medium"),
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  filled: {
    width: "50%",
    height: "50%",
    borderRadius: Number.MAX_SAFE_INTEGER,
    overflow: "hidden",
    backgroundColor: globalStyleColors.rgba("colorAccentCool", "opaque"),
  },
  filledBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("colorAccentCool", "xxlight"),
  },
});
