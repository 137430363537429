import { RecipeInstruction } from "@eatbetter/recipes-shared";
import React, { useEffect, useRef } from "react";
import { Dispatch } from "react";
import { LayoutAnimation, Platform, View } from "react-native";
import { globalStyleConstants } from "../GlobalStyles";
import { IconChevronLeft } from "../Icons";
import { Pressable } from "../Pressable";
import { Spacer } from "../Spacer";
import { TextInputHandle } from "../TextInput";
import { RecipeInstructionEditActions } from "./RecipeEditControl";
import { RecipeEditTextInput } from "./RecipeEditTextInput";
import { DeleteListItemButton } from "../Buttons";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { switchReturn } from "@eatbetter/common-shared";
import { Haptics } from "../Haptics";
import { useRecipeEditRemoveItemConfirmation } from "./RecipeEditRemoveConfirmation";

const strings = {
  placeholderText: "Instruction",
};

interface Props {
  sectionIndex: number;
  index: number;
  instructionCount: number;
  instruction: RecipeInstruction;
  dispatch: Dispatch<RecipeInstructionEditActions>;
  onAdd: () => void;
  addOnDone: boolean;
  /**
   * Whether to focus an empty input on add. Default is true.
   */
  autofocus?: boolean;
  admin: boolean;
}

export const RecipeInstructionEdit = React.memo((props: Props) => {
  const ref = useRef<TextInputHandle>(null);

  // autofocus wasn't working correctly with the keyboard aware scroll view when adding an ingredient
  // via the "+" button, but this does.
  useEffect(() => {
    if (props.instruction.text === "" && props.autofocus !== false) {
      ref.current?.focus();
    }
  }, []);

  const updateText = (text: string) => {
    props.dispatch({ type: "updateInstructionText", sectionIndex: props.sectionIndex, index: props.index, text });
  };

  const remove = () => {
    layoutAnimation();
    props.dispatch({ type: "removeItem", sectionIndex: props.sectionIndex, itemIndex: props.index });
  };

  const onRemove = useRecipeEditRemoveItemConfirmation({
    type: "instruction",
    isEmpty: !props.instruction.text,
    remove: remove,
  });

  const onDone = () => {
    // if it's the last item in the list, add a new item on done
    if (props.addOnDone) {
      props.onAdd();
    }
  };

  const convertToSection = () => {
    props.dispatch({ type: "convertToSection", sectionIndex: props.sectionIndex, itemIndex: props.index });
  };

  const move = (type: "up" | "down") => {
    const indexDelta = switchReturn(type, {
      up: -1,
      down: 1,
    });

    layoutAnimation();
    props.dispatch({
      type: "moveItem",
      sectionIndex: props.sectionIndex,
      fromIndex: props.index,
      toIndex: props.index + indexDelta,
    });
  };

  const moveUp = () => {
    move("up");
  };

  const moveDown = () => {
    move("down");
  };

  return (
    <View>
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginBottom: globalStyleConstants.unitSize / 4,
          }}
        >
          <DeleteListItemButton onPress={onRemove} size="xsmall" />
          <Spacer horizontal={1} />
          <RecipeEditTextInput
            ref={ref}
            value={props.instruction.text}
            onChangeText={updateText}
            placeholderText={strings.placeholderText}
            onSubmit={onDone}
          />
          <Spacer horizontal={1} />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {!!props.admin && (
              <>
                <Pressable onPress={convertToSection}>
                  <IconChevronLeft size={globalStyleConstants.unitSize * 1.5} />
                </Pressable>
                <Spacer horizontal={1} />
              </>
            )}
            <ListItemReorderControl
              isFirst={props.index === 0}
              isLast={props.index === props.instructionCount - 1}
              onPressMoveUp={moveUp}
              onPressMoveDown={moveDown}
            />
          </View>
        </View>
      </View>
    </View>
  );
});

function layoutAnimation() {
  if (Platform.OS === "web") {
    return;
  }
  Haptics.feedback("itemStatusChanged");
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}
