import { log } from "../../Log";
import { withErrorBoundary } from "../ErrorBoundary";
import { PhotoPlaceholder } from "../PhotoPlaceholder";
import { RemoteSvgComponent, RemoteSvgProps } from "./RemoteSvgInterfaces";

export const RemoteSvg: RemoteSvgComponent = withErrorBoundary(
  "RemoteSvgWeb",
  (props: RemoteSvgProps) => {
    // This .web version only exists to avoid a web bundling error when trying to resolve the import for SvgUri from react-native-svg
    // SVG images on web should be loaded with the react native Image component
    log.error("RemoteSvg should not render on web - this is a bug. Returning PhotoPlaceholder");
    return <PhotoPlaceholder style={props.style} type="default" />;
  },
  props => <PhotoPlaceholder style={props.style} type="default" />
);
