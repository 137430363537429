import { PhotoRef } from "@eatbetter/photos-shared";
import { DeglazeUser } from "@eatbetter/users-shared";
import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../GlobalStyles";
import { Pressable } from "../Pressable";
import { Photo } from "../Photo";
import { Separator } from "../Separator";
import { Spacer } from "../Spacer";
import { FontSize, TBody, TSecondary } from "../Typography";
import { PhotoPlaceholderType } from "../PhotoPlaceholder";
import { ListItemReorderControl } from "../ListItemReorderControl";
import { switchReturn } from "@eatbetter/common-shared";
import { IconMoreVertical } from "../Icons";

const strings = {
  recipeCount: (count: number) => `${count} recipe${count === 1 ? "" : "s"}`,
  collectionType: {
    system: "Smart Collection",
    household: "Household Collection",
  },
};

export const recipeCollectionCardHeight = 112;

interface Props {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  title: string;
  images: PhotoRef[];
  recipeCount: number;
  addedByUser?: DeglazeUser;
  collectionType: "system" | "user" | "household";
  isEditMode: boolean;
  marginHorizontal?: boolean;
  showCollectionType?: boolean;
}

export const RecipeCollectionCard = React.memo((props: Props) => {
  const onPressCollection = useCallback(() => {
    // COLLECTIONS-TODO
  }, []);

  const onPressMoveUp = useCallback(() => {
    // COLLECTIONS-TODO
  }, [props.index]);

  const onPressMoveDown = useCallback(() => {
    // COLLECTIONS-TODO
  }, [props.index]);

  const onPressMoreMenu = useCallback(() => {
    // COLLECTIONS-TODO
  }, []);

  return (
    <Pressable onPress={onPressCollection} singlePress noFeedback disabled={props.isEditMode}>
      <View
        style={[
          styles.card,
          props.marginHorizontal === false ? {} : { marginHorizontal: globalStyleConstants.minPadding },
        ]}
      >
        {props.isEditMode && (
          <>
            <Spacer horizontal={4} unit="pixels" />
            <ListItemReorderControl
              onPressMoveUp={onPressMoveUp}
              onPressMoveDown={onPressMoveDown}
              isFirst={props.isFirst}
              isLast={props.isLast}
            />
            <Spacer horizontal={1} />
          </>
        )}
        <PhotoGrid images={props.images} />
        <Spacer horizontal={1.5} />
        <CollectionPrimaryDetails
          title={props.title}
          recipeCount={props.recipeCount}
          collectionType={props.collectionType}
          showCollectionType={!!props.showCollectionType || props.isEditMode}
        />
        {props.isEditMode && (
          <>
            <Spacer horizontal={1} />
            <MoreMenu onPress={onPressMoreMenu} />
            <Spacer horizontal={4} unit="pixels" />
          </>
        )}
      </View>
    </Pressable>
  );
});

const PhotoGrid = React.memo((props: { images: PhotoRef[] }) => {
  const separatorStyleProps = { color: "white", lineWidth: 2 };

  const renderPhoto = (index: number) => {
    const getPlaceholderType = (): PhotoPlaceholderType => {
      switch (index) {
        case 0: {
          return "topLeftToBottomRight";
        }
        case 1: {
          return "topRightToBottomLeft";
        }
        case 2: {
          return "topLeftToBottomRight";
        }
        case 3: {
          return "topRightToBottomLeft";
        }
        default: {
          return "default";
        }
      }
    };

    return (
      <View style={{ flex: 1 }}>
        <Photo style="flexed" sourceSize="w1290" source={props.images[index]} placeholderStyle={getPlaceholderType()} />
      </View>
    );
  };

  const renderRow = (index: number) => {
    return (
      <View style={styles.photoRow}>
        {renderPhoto(2 * index + 0)}
        <Separator {...separatorStyleProps} orientation="column" />
        {renderPhoto(2 * index + 1)}
      </View>
    );
  };

  return (
    <View style={styles.photoGrid}>
      {renderRow(0)}
      <Separator {...separatorStyleProps} orientation="row" />
      {renderRow(1)}
    </View>
  );
});

const CollectionPrimaryDetails = React.memo(
  (props: {
    title: string;
    recipeCount: number;
    collectionType: Props["collectionType"];
    showCollectionType: boolean;
  }) => {
    const collectionTypeText = switchReturn(props.collectionType, {
      system: strings.collectionType.system,
      household: strings.collectionType.household,
      user: undefined,
    });

    return (
      <View style={styles.primaryDetails}>
        {props.showCollectionType && !!collectionTypeText && (
          <>
            <CollectionType text={collectionTypeText} />
            <Spacer vertical={1} />
          </>
        )}
        <CollectionTitle title={props.title} />
        <Spacer vertical={0.25} />
        <RecipeCount count={props.recipeCount} />
      </View>
    );
  }
);

const CollectionTitle = React.memo((props: { title: string }) => {
  // Slightly tighter than the default (1.4)
  const lineHeight = 1.2 * FontSize.body;

  return (
    <TBody numberOfLines={2} adjustsFontSizeToFit lineHeight={lineHeight}>
      {props.title}
    </TBody>
  );
});

const RecipeCount = React.memo((props: { count: number }) => {
  return <TSecondary opacity="light">{strings.recipeCount(props.count)}</TSecondary>;
});

const CollectionType = React.memo((props: { text: string }) => {
  return <TSecondary opacity="light">{props.text}</TSecondary>;
});

export const MoreMenu = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress}>
      <IconMoreVertical color={globalStyleColors.colorAccentCool} />
    </Pressable>
  );
});

const styles = StyleSheet.create({
  card: {
    height: recipeCollectionCardHeight,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: globalStyleConstants.defaultBorderRadius,
    padding: globalStyleConstants.minPadding,
    ...globalStyles.shadowItem,
  },
  photoGrid: {
    aspectRatio: 1,
    height: "100%",
    borderRadius: globalStyleConstants.unitSize,
    overflow: "hidden",
  },
  photoRow: {
    flex: 1,
    flexDirection: "row",
  },
  primaryDetails: {
    flexGrow: 1,
    flexShrink: 1,
    height: "100%",
    justifyContent: "center",
    paddingVertical: globalStyleConstants.unitSize,
  },
});
