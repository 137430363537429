import React from "react";
import { GradientComponent, GradientProps } from "./GradientInterfaces";
import { View } from "react-native";
import { bottomThrow } from "@eatbetter/common-shared";
import { globalStyleColors } from "../GlobalStyles";

export const Gradient: GradientComponent = React.memo((props: GradientProps) => {
  const getGradient = (): string => {
    switch (props.type) {
      case "photoPlaceholder": {
        return `linear-gradient(to bottom, ${globalStyleColors.white}, ${globalStyleColors.colorGreyDark})`;
      }
      case "screenBackground": {
        return `linear-gradient(to bottom, ${globalStyleColors.white}, ${globalStyleColors.colorGreyLight})`;
      }
      case "shimmer": {
        return `linear-gradient(to right, ${globalStyleColors.rgba("white", "transparent")}, ${globalStyleColors.rgba(
          "white",
          "light"
        )}, ${globalStyleColors.rgba("white", "transparent")})`;
      }
      case "fadeOutVertical": {
        return `linear-gradient(to bottom, ${globalStyleColors.rgba("white", "transparent")}, ${globalStyleColors.rgba(
          "white",
          "opaque"
        )}`;
      }
      case "bottomLeftToTopRight": {
        return `linear-gradient(to top right, ${globalStyleColors.white}, ${globalStyleColors.colorGreyDark})`;
      }
      case "bottomRightToTopLeft": {
        return `linear-gradient(to top left, ${globalStyleColors.white}, ${globalStyleColors.colorGreyDark})`;
      }
      case "topLeftToBottomRight": {
        return `linear-gradient(to bottom right, ${globalStyleColors.white}, ${globalStyleColors.colorGreyDark})`;
      }
      case "topRightToBottomLeft": {
        return `linear-gradient(to bottom left, ${globalStyleColors.white}, ${globalStyleColors.colorGreyDark})`;
      }
      default:
        bottomThrow(props.type);
    }
  };

  const gradientStyle = {
    background: getGradient(),
    flex: 1,
  };

  return <View style={gradientStyle}>{props.children}</View>;
});
