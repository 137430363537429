import { useCallback, useMemo, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView, View } from "react-native";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar } from "../components/BottomActionBar";
import { globalStyleConstants, globalStyles } from "../components/GlobalStyles";
import React from "react";
import { TextInput, TextInputHandle } from "../components/TextInput";
import { SectionHeading } from "../components/SectionHeading";
import { Spacer } from "../components/Spacer";

const strings = {
  screenTitle: "Create Recipe Collection",
  submit: "Create Collection",
  collectionNameSectionHeader: "Collection Name",
  collectionNamePlaceholder: "Collection name",
  collectionSectionSectionHeader: "Collection Section",
};

export const CreateRecipeCollectionScreen = withScreenContainer("CreateRecipeCollectionScreen", () => {
  const screen = useScreen();

  const [collectionName, setCollectionName] = useState("");

  const { headerHeight } = useScreenElementDimensions();

  const onSubmit = useCallback(() => {
    // COLLECTIONS-TODO
  }, []);

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      type: "default",
      title: strings.screenTitle,
      right: {
        type: "cancel",
        onPress: screen.nav.goBack,
      },
      backgroundColor: "white",
    };
  }, [screen.nav.goBack]);

  return (
    <ScreenView header={screenHeader} scrollView={false} paddingVertical="none" paddingHorizontal="none">
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: globalStyleConstants.defaultPadding,
          paddingTop: headerHeight + 2 * globalStyleConstants.unitSize,
        }}
      >
        <SectionHeading text={strings.collectionNameSectionHeader} noPadding />
        <Spacer vertical={1} />
        <CollectionNameTextInput
          value={collectionName}
          onChangeText={setCollectionName}
          placeholderText={strings.collectionNamePlaceholder}
        />
        <Spacer vertical={2} />
        <SectionHeading text={strings.collectionSectionSectionHeader} noPadding />
      </ScrollView>
      <InputAccessoryView snapPoint={{ bottom: "tabBar" }}>
        <BottomActionBar
          primaryAction={{ actionText: strings.submit, onPressAction: onSubmit }}
          disableSnapToBottom
          containerBackgroundColor="transparent"
        />
      </InputAccessoryView>
    </ScreenView>
  );
});

interface Props {
  placeholderText: string;
  value: string | undefined;
  onChangeText: (v: string) => void;
  onSubmit?: () => void;
}

const CollectionNameTextInput = React.forwardRef<TextInputHandle, Props>((props, ref) => {
  return (
    <View
      style={{
        flex: 1,
        height: 48,
        borderRadius: Number.MAX_SAFE_INTEGER,
        paddingHorizontal: globalStyleConstants.defaultPadding,
        paddingVertical: globalStyleConstants.minPadding,
        justifyContent: "center",
        backgroundColor: "white",
        ...globalStyles.shadowTextInput,
      }}
    >
      <TextInput
        ref={ref}
        value={props.value}
        onChangeText={props.onChangeText}
        returnKeyType="done"
        onSubmit={props.onSubmit}
        placeholderText={props.placeholderText}
        noPadding
        noBorder
      />
    </View>
  );
});
