import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { loadRecipes, recipeLibraryFiltersChanged, searchRecipeLibrary } from "../lib/recipes/RecipesThunks";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { navTree, RecipesHomeScreenProps } from "../navigation/NavTree";
import { AddItemButton } from "../components/Buttons";
import { Spacer } from "../components/Spacer";
import { useTabRenderDelay } from "../lib/util/UseTabRenderDelay";
import {
  RecipeListSections,
  useFilteredRecipeListSections,
  useLastScrollListToTopAction,
  useRecipeSearchPhrase,
} from "../lib/composite/RecipeListSelectors";
import { FontSize, TBody, Text, THeading2 } from "../components/Typography";
import { useInitialRecipeLoadComplete, useLibraryViewMode } from "../lib/recipes/RecipesSelectors";
import { HeaderProps } from "../components/ScreenHeaders";
import {
  AppRecipeTag,
  libraryViewModeChanged,
  RecipeLibraryViewMode,
  removeAllLibraryFilters,
  removeTagFromLibraryFilter,
} from "../lib/recipes/RecipesSlice";
import { useFilterTags } from "../lib/recipes/RecipeTagSelectors";
import { Haptics } from "../components/Haptics";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { useCheckpointCompleted, useIsAnonymousUser, useSystemSetting } from "../lib/system/SystemSelectors";
import { useBottomSheet } from "./BottomSheetScreen";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import {
  reportAddRecipeButtonTapped,
  reportLibraryAddFilterButtonPressed,
  reportPhotoIngestionWalkthroughCompleted,
  reportPhotoIngestionWalkthroughStarted,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { switchReturn, tryParseBool } from "@eatbetter/common-shared";
import { maybePromptForReview } from "../lib/system/SystemThunks";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { globalStyleColors } from "../components/GlobalStyles";
import { ShareExtensionDemoVideo } from "./OnboardShareExtensionScreen";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { Pressable } from "../components/Pressable";
import { IconChevronDown } from "../components/Icons";
import { useDispatch } from "../lib/redux/Redux";
import { RecipeLibraryListView } from "../components/recipes/RecipeLibraryListView";
import { log } from "../Log";
import { HeaderRightProps } from "../components/ScreenHeaderRightButtons";
import { useRecipeLibraryViews } from "../components/recipes/RecipeLibraryInterfaces";
import { RecipeLibraryCollectionsView } from "../components/recipes/RecipeLibraryCollectionsView";
import { Freeze } from "react-freeze";
import { TextInputHandle } from "../components/TextInput";

const strings = {
  headerMyRecipes: "My Recipes",
  headerMyCollections: "My Collections",
  emptyState: {
    headline: "Save any recipe right\nfrom your browser",
    subhead: "",
    dontSeeDeglaze: "Don't see Deglaze?",
  },
  searchPlaceholder: "Search your library",
  addMenu: {
    createCollection: "New Recipe Collection",
    addFromUrl: "Import Recipe from URL",
    addFromPhotos: "Import Recipe from Photos",
    addManual: "Enter Your Own Recipe",
  },
  addManualAction: "add your own recipe",
  collectionsMenu: {
    newCollection: "New Recipe Collection",
    reorderAndEdit: "Reorder + Edit Collections",
  },
};

export const RecipesHomeScreen = withScreenContainer(
  "RecipesHomeScreen",
  (props: RecipesHomeScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const sections = useFilteredRecipeListSections();
    const shouldRender = useTabRenderDelay(500, screen.nav.focused);

    const searchPhrase = useRecipeSearchPhrase() ?? "";
    const alreadyPromptedForReview = useRef(false);

    useEffect(() => {
      if (props.cookingSessionJustSaved && screen.nav.focused && !alreadyPromptedForReview.current) {
        alreadyPromptedForReview.current = true;
        // wait for any navigation animation to end - tested on a device and this timing seems to work well
        setTimeout(() => {
          dispatch(maybePromptForReview("Cooking Session Saved"));
        }, 500);
      }
    }, [props.cookingSessionJustSaved, dispatch, screen.nav.focused, alreadyPromptedForReview]);

    const setSearchPhrase = useCallback(
      (s: string) => {
        // I tried debouncing by using a state variable to set the display text
        // and then use a debounced function called from the change handler to update
        // redux. Performance was better without the debounce. The lagginess in the sim
        // is much less of an issue on device, even with 1000 recipes.
        dispatch(searchRecipeLibrary({ query: s }));
      },
      [dispatch]
    );

    const onPullToRefresh = useCallback(() => {
      return dispatch(loadRecipes("full"));
    }, [dispatch]);

    return React.createElement(RecipesHomeScreenComponent, {
      shouldRender,
      sections,
      searchPhrase,
      setSearchPhrase,
      onPullToRefresh,
    });
  },
  {
    serializer: {
      cookingSessionJustSaved: { optional: true, fn: b => (b ? "1" : "0") },
    },
    parser: {
      cookingSessionJustSaved: { optional: true, fn: b => tryParseBool(b) ?? false },
    },
  }
);

interface Props {
  shouldRender: boolean;
  sections: RecipeListSections;
  searchPhrase: string;
  setSearchPhrase: (str: string) => void;
  onPullToRefresh: () => Promise<void>;
}

export const RecipesHomeScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const photosEnabled = !!useSystemSetting("recipePhotoIngestion");
  const photosWalkthroughCompleted = useCheckpointCompleted("photoRecipeWalkthroughCompleted");

  // Photo ingestion walkthrough for existing users to discover it
  const walkthroughEnabled = screen.nav.focused && photosEnabled && !photosWalkthroughCompleted;
  const badgePhotoIngestionMenuItem = useRef(false);

  const searchBoxRef = useRef<TextInputHandle>(null);

  // Determine what view mode we're in (allRecipes | collections)
  const libraryViewMode = useLibraryViewMode();

  const libraryViews = useRecipeLibraryViews();
  const currentLibraryView = libraryViews[libraryViewMode];

  const [collectionsEditMode, setCollectionsEditMode] = useState(false);

  const saveAndClearCollectionsEditMode = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setCollectionsEditMode(false);
  }, [setCollectionsEditMode]);

  useEffect(() => {
    if (walkthroughEnabled) {
      dispatch(analyticsEvent(reportPhotoIngestionWalkthroughStarted()));
      badgePhotoIngestionMenuItem.current = true;
    }
  }, [walkthroughEnabled]);

  const endPhotoIngestionWalkthrough = useCallback(() => {
    dispatch(analyticsEvent(reportPhotoIngestionWalkthroughCompleted()));
    dispatch(checkpointsCompleted(["photoRecipeWalkthroughCompleted"]));
  }, [dispatch]);

  const onPressAddItem = useCallback(() => {
    dispatch(analyticsEvent(reportAddRecipeButtonTapped()));
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <RecipeAddMenu badgePhotoIngestion={badgePhotoIngestionMenuItem.current} />,
      height: getAddRecipeSheetHeight({ libraryViewMode, photosEnabled }),
    });
  }, [dispatch, screen.nav.modal, photosEnabled, libraryViewMode]);

  // FILTERS

  const activeTagFilters = useFilterTags(undefined);
  const hasActiveSearchFilter = !!props.searchPhrase;
  const hasActiveTagFilters = activeTagFilters.length > 0;
  const searchAndFilterBarHeight = useSearchAndFilterBarHeight(activeTagFilters);

  const onPressFilterButton = useCallback(() => {
    dispatch(analyticsEvent(reportLibraryAddFilterButtonPressed()));
    screen.nav.modal(navTree.get.screens.recipesFilter, { searchSessionId: undefined });
    setTimeout(() => currentLibraryView.ref.current?.scrollToTop({ animated: false }), 500);
  }, [dispatch, screen.nav.modal, currentLibraryView]);

  const removeFilter = useCallback(
    (tag: AppRecipeTag) => {
      Haptics.feedback("itemDeleted");
      currentLibraryView.ref.current?.scrollToTop();
      dispatch(removeTagFromLibraryFilter(tag));
      dispatch(recipeLibraryFiltersChanged());
    },
    [dispatch, currentLibraryView]
  );

  const onPressClearSearchAndFilters = useCallback(() => {
    Haptics.feedback("itemDeleted");
    currentLibraryView.ref.current?.scrollToTop();
    dispatch(removeAllLibraryFilters());
  }, [dispatch, currentLibraryView]);

  const onChangeSearchPhrase = useCallback(
    (value: string) => {
      currentLibraryView.ref.current?.scrollToTop();
      props.setSearchPhrase(value);
    },
    [currentLibraryView, props.searchPhrase]
  );

  const recipesLoading = !useInitialRecipeLoadComplete();
  const noResults = Object.values(props.sections).every(i => i.length === 0);
  const emptyState = !hasActiveTagFilters && !hasActiveSearchFilter && noResults && !recipesLoading;

  const showShareExtensionSetup = !useCheckpointCompleted("shareExtensionUsed") && !emptyState;

  const renderSearchAndFilterBar = useCallback(() => {
    if (emptyState || recipesLoading) {
      return null;
    }

    return (
      <SearchAndFilterBar
        ref={searchBoxRef}
        placeholderText={strings.searchPlaceholder}
        searchPhrase={props.searchPhrase}
        onChangeSearchPhrase={onChangeSearchPhrase}
        onPressFilterButton={onPressFilterButton}
        activeTagFilters={activeTagFilters}
        onRemoveFilter={removeFilter}
        showCancelButton={hasActiveTagFilters}
        onPressCancel={onPressClearSearchAndFilters}
        onFocus={saveAndClearCollectionsEditMode}
      />
    );
  }, [
    emptyState,
    recipesLoading,
    props.searchPhrase,
    onChangeSearchPhrase,
    onPressFilterButton,
    hasActiveTagFilters,
    activeTagFilters,
    removeFilter,
    onPressClearSearchAndFilters,
    saveAndClearCollectionsEditMode,
    searchBoxRef,
  ]);

  const getSearchAndFilterBarHeight = useCallback(() => {
    if (emptyState) {
      return 0;
    }
    return searchAndFilterBarHeight;
  }, [emptyState, searchAndFilterBarHeight]);

  // Animate layout changes
  useEffect(() => {
    if (!screen.nav.focused) {
      return;
    }

    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [props.sections, activeTagFilters, screen.nav.focused]);

  const lastScrollListToTopAction = useLastScrollListToTopAction();
  const alreadyActedOnLastScrollToTopAction = useRef(lastScrollListToTopAction);

  // Scroll the list to the top if the user has taken an action that results in the item they were interacting with moving
  // this currently includes adding a recipe to the grocery list and starting a cooking session. We currently do *not*
  // do these for the inverse (removing from the list, ending a cooking session).
  useEffect(() => {
    // don't scroll if this is somehow updated when the user is on the screen, which
    // currently shouldn't be possible, but would be if we add quick actions on the list items
    if (!screen.nav.focused && lastScrollListToTopAction !== alreadyActedOnLastScrollToTopAction.current) {
      alreadyActedOnLastScrollToTopAction.current = lastScrollListToTopAction;
      currentLibraryView.ref.current?.scrollToTop();
    }
  }, [screen.nav.focused, lastScrollListToTopAction, alreadyActedOnLastScrollToTopAction]);

  const onPressReorderAndEdit = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setCollectionsEditMode(true);
  }, [setCollectionsEditMode]);

  // If the user navs away, blur the search box and end edit mode if either are active
  useEffect(() => {
    if (!screen.nav.focused) {
      searchBoxRef.current?.blur();
      saveAndClearCollectionsEditMode();
    }
  }, [screen.nav.focused]);

  const [disableHeaderRetraction, setDisableHeaderRetraction] = useState(false);

  useEffect(() => {
    if (collectionsEditMode) {
      currentLibraryView.ref.current?.scrollToTop();
      setTimeout(() => setDisableHeaderRetraction(true), 100);
    }
  }, [collectionsEditMode]);

  const openCollectionsMenu = useCollectionsMenu({ onPressReorderAndEdit });

  const screenHeader = useMemo<HeaderProps>(() => {
    const right: HeaderRightProps | undefined =
      libraryViewMode === "collections"
        ? collectionsEditMode
          ? {
              type: "done",
              onPress: saveAndClearCollectionsEditMode,
              bold: true,
              color: globalStyleColors.colorTextLink,
            }
          : {
              type: "menu",
              onPress: openCollectionsMenu,
            }
        : undefined;

    return {
      type: "custom",
      style: "tabRoot",
      title: () => <RecipeLibraryScreenTitle />,
      right,
      animationConfig: {
        disableRetract: disableHeaderRetraction,
        animationProgress: currentLibraryView.headerPosition,
      },
      subHeaderComponent: { render: renderSearchAndFilterBar, getHeight: getSearchAndFilterBarHeight },
    };
  }, [
    libraryViewMode,
    currentLibraryView,
    renderSearchAndFilterBar,
    getSearchAndFilterBarHeight,
    openCollectionsMenu,
    collectionsEditMode,
    saveAndClearCollectionsEditMode,
    disableHeaderRetraction,
  ]);

  // -------- RENDER STARTS HERE --------

  return (
    <ScreenView
      scrollView={false}
      paddingVertical={false}
      paddingHorizontal={false}
      header={screenHeader}
      loading={!props.shouldRender || recipesLoading}
    >
      {emptyState && <EmptyState />}
      {!emptyState && (
        <>
          <LibraryViewContainer isVisible={libraryViewMode === "allRecipes"}>
            <RecipeLibraryListView
              ref={libraryViews.allRecipes.ref}
              sections={props.sections}
              onPullToRefresh={props.onPullToRefresh}
              onScroll={libraryViews.allRecipes.onScroll}
              showShareExtensionTip={showShareExtensionSetup}
            />
          </LibraryViewContainer>
          <LibraryViewContainer isVisible={libraryViewMode === "collections"}>
            <RecipeLibraryCollectionsView
              ref={libraryViews.collections.ref}
              onPullToRefresh={props.onPullToRefresh}
              onScroll={libraryViews.collections.onScroll}
              editMode={collectionsEditMode}
            />
          </LibraryViewContainer>
        </>
      )}
      {!collectionsEditMode && (
        <AddItemButton
          onPress={onPressAddItem}
          walkthroughEnabled={walkthroughEnabled ? "photoIngestion" : undefined}
          onWalkthroughNext={endPhotoIngestionWalkthrough}
        />
      )}
    </ScreenView>
  );
};

const LibraryViewContainer = React.memo((props: PropsWithChildren<{ isVisible: boolean }>) => {
  return <Freeze freeze={!props.isVisible}>{props.children}</Freeze>;
});

const navDelay = 100;

function getAddRecipeSheetHeight(args: { photosEnabled: boolean; libraryViewMode: RecipeLibraryViewMode }) {
  let menuHeight = 2;

  if (args.photosEnabled) {
    menuHeight += 1;
  }
  if (args.libraryViewMode === "collections") {
    menuHeight += 1;
  }

  return getOptionsMenuHeight(menuHeight);
}

const RecipeAddMenu = React.memo((props: { badgePhotoIngestion?: boolean }) => {
  const screen = useScreen();
  const bottomSheet = useBottomSheet();
  const photosEnabled = !!useSystemSetting("recipePhotoIngestion");
  const libraryViewMode = useLibraryViewMode();

  const addFromUrl = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => screen.nav.goTo("push", navTree.get.screens.recipeAddFromUrl), navDelay);
  }, [bottomSheet, screen.nav.goTo]);

  const isAnon = useIsAnonymousUser();
  const addFromPhotos = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      setTimeout(
        () =>
          navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction }),
        navDelay
      );
    } else {
      setTimeout(() => screen.nav.goTo("push", navTree.get.screens.recipeAddFromPhotos), navDelay);
    }
  }, [bottomSheet, isAnon, screen.nav.goTo]);

  const addManual = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      setTimeout(
        () =>
          navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction }),
        navDelay
      );
    } else {
      setTimeout(() => screen.nav.goTo("push", navTree.get.screens.recipeAddManual), navDelay);
    }
  }, [bottomSheet, isAnon, screen.nav.goTo]);

  const createCollection = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      setTimeout(
        () =>
          navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction }),
        navDelay
      );
    } else {
      setTimeout(() => screen.nav.goTo("push", navTree.get.screens.createRecipeCollection), navDelay);
    }
  }, [bottomSheet, isAnon, screen.nav.goTo]);

  return (
    <OptionsMenu>
      {libraryViewMode === "collections" && (
        <OptionsMenuItem icon="add" text={strings.addMenu.createCollection} isFirst onPress={createCollection} />
      )}
      <OptionsMenuItem
        isFirst={libraryViewMode !== "collections"}
        icon="link"
        text={strings.addMenu.addFromUrl}
        onPress={addFromUrl}
      />
      {photosEnabled && (
        <OptionsMenuItem
          icon="camera"
          text={strings.addMenu.addFromPhotos}
          onPress={addFromPhotos}
          badge={props.badgePhotoIngestion ? "new" : undefined}
        />
      )}
      <OptionsMenuItem icon="editPencil" text={strings.addMenu.addManual} onPress={addManual} />
    </OptionsMenu>
  );
});

const EmptyState = React.memo(() => {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const onPressAddFirstRecipe = useCallback(() => {
    nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [dispatch]);

  return (
    <View style={styles.emptyStateContainer}>
      <THeading2 numberOfLines={2} adjustsFontSizeToFit align="center">
        {strings.emptyState.headline}
      </THeading2>
      <Spacer vertical={0.5} />
      <View style={{ height: "55%" }}>
        {/* Don't report analytics since the user isn't actively tapping to watch the video */}
        <ShareExtensionDemoVideo />
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TBody
          onPress={onPressAddFirstRecipe}
          suppressHighlighting
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
        >
          {strings.emptyState.dontSeeDeglaze}
        </TBody>
      </View>
    </View>
  );
});

/**
 * Pressable screen title that controls view switching between classic list view and collections.
 */
const RecipeLibraryScreenTitle = React.memo(() => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const libraryViewMode = useLibraryViewMode();

  const collectionsEnabled = useSystemSetting("recipeCollections");

  const text = switchReturn(libraryViewMode, {
    allRecipes: strings.headerMyRecipes,
    collections: strings.headerMyCollections,
  });

  const fontSize = "h1";
  const fontScale = 28 / FontSize[fontSize];

  const onPressSwitchView = useCallback(
    (newMode: RecipeLibraryViewMode) => {
      dispatch(libraryViewModeChanged(newMode));
    },
    [dispatch]
  );

  const onPressScreenHeader = useCallback(() => {
    if (!collectionsEnabled) {
      log.error("Recipe library screen header onPress callback called but collections feature is disabled. Returning");
      return;
    }

    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <SwitchViewSheet currentView={libraryViewMode} onPressSwitchView={onPressSwitchView} />,
      height: getOptionsMenuHeight(2),
    });
  }, [libraryViewMode, onPressSwitchView, collectionsEnabled]);

  return (
    <Pressable
      style={{ flexDirection: "row", alignItems: "center" }}
      onPress={onPressScreenHeader}
      noFeedback
      disabled={!collectionsEnabled}
    >
      <Text fontSize={fontSize} font="serif" enableFontScaling="upAndDown" scale={fontScale}>
        {text}
      </Text>
      {!!collectionsEnabled && (
        <>
          <Spacer horizontal={0.5} />
          <IconChevronDown opacity="opaque" size={20} strokeWidth={2} />
        </>
      )}
    </Pressable>
  );
});

const SwitchViewSheet = React.memo(
  (props: { currentView: RecipeLibraryViewMode; onPressSwitchView: (newMode: RecipeLibraryViewMode) => void }) => {
    const bottomSheet = useBottomSheet();

    const onPressSwitchView = (newView: RecipeLibraryViewMode) => {
      bottomSheet?.closeSheetAndGoBack();
      props.onPressSwitchView(newView);
    };

    const getIcon = (option: RecipeLibraryViewMode) => {
      return props.currentView === option ? "selected" : "unselected";
    };

    return (
      <OptionsMenu>
        <OptionsMenuItem
          icon={getIcon("allRecipes")}
          text={strings.headerMyRecipes}
          onPress={() => onPressSwitchView("allRecipes")}
          isFirst
        />
        <OptionsMenuItem
          icon={getIcon("collections")}
          text={strings.headerMyCollections}
          onPress={() => onPressSwitchView("collections")}
        />
      </OptionsMenu>
    );
  }
);

function useCollectionsMenu(args: { onPressReorderAndEdit: () => void }) {
  const { onPressReorderAndEdit } = args;
  const screen = useScreen();

  const openCollectionsMenu = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <CollectionsMenu onPressReorderAndEdit={onPressReorderAndEdit} />,
      height: getOptionsMenuHeight(2),
    });
  }, [screen.nav.modal, onPressReorderAndEdit]);

  return openCollectionsMenu;
}

const CollectionsMenu = React.memo((props: { onPressReorderAndEdit: () => void }) => {
  const screen = useScreen();
  const bottomSheet = useBottomSheet();
  const isAnon = useIsAnonymousUser();

  const onPressCreateCollection = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    if (isAnon) {
      setTimeout(
        () =>
          navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.addManualAction }),
        navDelay
      );
    } else {
      setTimeout(() => screen.nav.goTo("push", navTree.get.screens.createRecipeCollection), navDelay);
    }
  }, [screen.nav.goTo, bottomSheet, isAnon]);

  const onPressReorderAndEdit = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    props.onPressReorderAndEdit();
  }, [props.onPressReorderAndEdit, bottomSheet]);

  return (
    <OptionsMenu>
      <OptionsMenuItem
        isFirst
        icon="add"
        text={strings.collectionsMenu.newCollection}
        onPress={onPressCreateCollection}
      />
      <OptionsMenuItem icon="sort" text={strings.collectionsMenu.reorderAndEdit} onPress={onPressReorderAndEdit} />
    </OptionsMenu>
  );
});

const styles = StyleSheet.create({
  emptyStateContainer: {
    flex: 1,
    paddingHorizontal: 22,
    justifyContent: "center",
    alignItems: "center",
  },
});
