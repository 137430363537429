import { SharedValue, useAnimatedScrollHandler } from "react-native-reanimated";
import { useMemo, useRef } from "react";
import { useHeaderScrollAnimation } from "../ScreenHeaders";
import { RecipeLibraryViewMode } from "../../lib/recipes/RecipesSlice";

export type ScrollEventHandler = ReturnType<typeof useAnimatedScrollHandler>;

export interface RecipeLibraryImperativeHandle {
  scrollToTop: (opts?: { animated: boolean }) => void;
}

export function useRecipeLibraryViews() {
  const allRecipesRef = useRef<RecipeLibraryImperativeHandle>(null);
  const collectionsRef = useRef<RecipeLibraryImperativeHandle>(null);

  const [allRecipesHeaderPosition, allRecipesOnScroll] = useHeaderScrollAnimation();
  const [collectionsHeaderPosition, collectionsOnScroll] = useHeaderScrollAnimation();

  return useMemo<
    Record<
      RecipeLibraryViewMode,
      {
        ref: React.RefObject<RecipeLibraryImperativeHandle>;
        headerPosition: SharedValue<number>;
        onScroll: ReturnType<typeof useAnimatedScrollHandler>;
      }
    >
  >(() => {
    return {
      allRecipes: { ref: allRecipesRef, headerPosition: allRecipesHeaderPosition, onScroll: allRecipesOnScroll },
      collections: { ref: collectionsRef, headerPosition: collectionsHeaderPosition, onScroll: collectionsOnScroll },
    };
  }, [
    allRecipesRef,
    collectionsRef,
    allRecipesHeaderPosition,
    collectionsHeaderPosition,
    allRecipesOnScroll,
    collectionsOnScroll,
  ]);
}
